<template>
  <div class="wrap">
    <createIndex v-show="active === 1" :active.sync="active" @select="select" />
    <buyStep
      v-if="
        (active === 2 && product) ||
        ($route.query.orderNo && $route.query.money)
      "
      :active.sync="active"
      :product="product"
      :sign="sign"
    />
  </div>
</template>
<script>
import createIndex from '@/views/creation/CreateStep/components/createIndex.vue'
import buyStep from '@/views/creation/CreateStep/components/buyStep.vue'

export default {
  name: 'goBuyActivate',
  components: { createIndex, buyStep },
  data () {
    return {
      product: null,
      active: 1,
      sign: null
    }
  },
  mounted () {
    this.active = this.$route.query.orderNo && this.$route.query.money ? 2 : 1
  },
  methods: {
    select (e, i) {
      this.product = e
      this.sign = i
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  margin-top: 70px;
  background-color: #eaeaea;
  ::v-deep .cardContent {
    padding: 10px;
  }
}
</style>
