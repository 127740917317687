<template>
  <div class="product-service">
    <div class="title">
      <div class="name">
        <span>隼云</span>
        <span>标准版产品服务</span>
      </div>
    </div>
    <div class="content">
      <div class="order" v-show="cur === 0">
        <div>产品版本</div>
        <div class="versions">{{productDetail.versionName}}</div>
        <div>购买时长</div>
        <div class="duration">{{productDetail.serviceTime}}</div>
        <div>用户人数</div>
        <div class="amount">
          <el-input-number
            v-model="productDetail.maxUsers"
            controls-position="right"
            @change="handleChange"
            :min="1"
            :max="10"
            size="mini"
          ></el-input-number
          >人
          <span></span>
        </div>
        <div>
        </div>
        <div class="hr"></div>
        <div class="total">
          <span>产品价格</span>
          <span class="symbol">¥</span>
          <span class="price">{{productDetail.productPrice}}.00</span>
        </div>
        <div class="right">
          <div class="btn">
            <el-button type="primary" size="mini" @click="submit('order',productDetail.versionId,productDetail.maxUsers)"
              >提交订单</el-button
            >
          </div>
          <el-checkbox class="tcp" v-model="checked" style="fontSize:10px"
            >我已阅读同意《隼云科技服务协议》</el-checkbox
          >
        </div>
      </div>
      <div class="pay" v-show="cur === 1">
        <div class="detail">
          <div class="left">
            <span style="backgroundColor:#00b42a; padding:5px;borderRadius:50%">
              <i class="el-icon-check" style="color:#fff"></i>
            </span>
          </div>
          <div class="middle">
            <div class="hint">订单提交成功！ 请尽快完成支付</div>
            <div>请在<span style="color:red">{{orderDetail.orderTimeOut}}分钟</span>内完成支付，超时后将自动取消订单</div>
            <div>订单编号<span style="color:red">{{orderDetail.outTradeNo}}</span></div>
          </div>
          <div class="right">
            <span class="symbol">¥</span>
            <span>{{orderDetail.orderAmount}}</span>
          </div>
        </div>
        <div class="hr"></div>
        <div class="info">
          <div>商品信息</div>
          <!-- <el-table :data="tableData" style="width: 100%;margin:25px 0;">
            <el-table-column prop="versions" label="产品名称" width="150">
            </el-table-column>
            <el-table-column prop="size" label="规格" width="150">
            </el-table-column>
            <el-table-column prop="maxUsers" label="用户数" width="150">
            </el-table-column>
            <el-table-column prop="serviceTime" label="购买时长" width="150">
            </el-table-column>
          </el-table> -->
          <table cellspacing='0' style="width: 600px;margin:25px 0;textAlign:left;fontSize:14px;textAlign:center">
            <tr style="backgroundColor:#f8f8f9">
              <td>产品名称</td>
              <td>产品版本</td>
              <td>用户数</td>
              <td>购买时长</td>
            </tr>
            <tr style="backgroundColor:#ffffff;fontSize:12px">
              <td>{{orderDetail.productName}}</td>
              <td>{{orderDetail.versionName}}</td>
              <td>{{orderDetail.maxUsers}}</td>
              <td>{{orderDetail.serviceTime}}</td>
            </tr>
          </table>
        </div>
        <div style="text-align: right;">
        <el-button type="primary" size="mini" @click="pay" >立即支付</el-button>
    </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductService',
  props:{
    productDetail:{
      type:Object,
      required: true
    },
    orderDetail:{
      type:Object,
      required:true
    },
    orderShow:{
      type:Number,
      default:0
    }
  },
  watch:{
    orderShow(newV){
      this.cur=newV
    }
  },
  data () {
    return {
      num: 1,
      checked: false,
      cur: 0,
      /* tableData:[
        {
            versions:'1.0.0',
            size:'标准版',
            total:'3',
            date:'一年'
        }
      ], */
    }
  },
  created(){
  },
  computed:{
  },
  methods: {
    handleChange (value) {
      console.log(value)
    },
    //提交订单事件
    submit (unlike,id) {
      if(!this.checked) return this.$message.error('请阅读同意《隼云科技服务协议》')
      if(unlike==='order'){
        console.log('进来了')
        this.$emit('click',id)
      }
    },
    //立即支付事件
    pay(){
      this.$router.push('/Creation/console')
    }
  }
}
</script>

<style lang="scss" scoped>
.product-service {
  .title {
    height: 35px;
    background-color: #165dff;
    text-align: center;
    line-height: 35px;
    .name {
      font-size: 12px;
      color: #fff;
    }
  }
  .content {
    background-color: #fff;
    height: 60vh;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    .order {
      font-size: 10px;
      width: 25vw;
      color: #9799a5;
      .versions,
      .duration {
        display: block;
        border: 1px solid #b2c9ff;
        background-color: #e7eeff;
        width: 120px;
        text-align: center;
        padding: 5px 0;
        margin: 10px 0;
      }
      .amount {
        margin: 10px 0;
      }
      .hr {
        height: 2px;
        background-color: #f9f9fa;
        margin: 15px 0;
      }
      .total {
        height: 50px;
        background-color: #f7f8fa;
        line-height: 50px;
        padding: 0 10px;
        .symbol {
          margin: 0 10px;
          color: red;
          font-size: 16px;
        }
        .price {
          color: red;
          font-size: 16px;
        }
      }
      .right {
        text-align: right;
        margin-top: 20px;
        .btn {
          margin-bottom: 20px;
        }
      }
    }
    .pay{
        .detail{
            height:120px ;
            background-color: #f8f8f9;
            display: flex;
            align-items: center;
            .left{
                flex: 1;
                text-align: center;
            }
            .middle{
                flex: 4;
                font-size: 12px;
                margin: 0 20px;
                .hint{
                    font-size: 14px;
                    margin-bottom: 5px;
                }
            }
            .right{
                flex:1.5;
                text-align: center;
                color: red;
                font-size: 20px;
                .symbol{
                    margin: 0 10px;
                }
            }
        }
        .hr{
            height: 2px;
            background-color: #f8f9f9;
            margin: 30px 0;
        }
        .info{
          table{
            tr{
              line-height: 40px;
              td{
                border-bottom: 1px solid #ebeef5;
              }
            }
            
          }
        }
    }
  }
}
</style>
