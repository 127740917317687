var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("createIndex", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.active === 1,
            expression: "active === 1",
          },
        ],
        attrs: { active: _vm.active },
        on: {
          "update:active": function ($event) {
            _vm.active = $event
          },
          select: _vm.select,
        },
      }),
      (_vm.active === 2 && _vm.product) ||
      (_vm.$route.query.orderNo && _vm.$route.query.money)
        ? _c("buyStep", {
            attrs: { active: _vm.active, product: _vm.product, sign: _vm.sign },
            on: {
              "update:active": function ($event) {
                _vm.active = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }