<template>
  <div class="container">
    <div id="wrap">
      <UserInfo></UserInfo>
    </div>
  </div>
</template>

<script>
import UserInfo from '@/views/components/userInfo/index.vue'

export default {
  name: 'index',
  components: {
    UserInfo
  },
  data () {
    return {
      vivwH: 0 //页面高度
    }
  },
  computed: {
    tableHeight () {
      return this.vivwH - 40
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap')?.clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.vivwH = document.getElementById('wrap')?.clientHeight
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  width: 100%;
  padding: 70px 200px;
}
</style>
