<template>
  <div class="wap" :class="['creation', $route.path.split('/').pop()]">
    <router-view v-if="$route.meta.noLayout" />
    <template v-else>
      <div class="nav">
        <div class="title">
          <div class="left">
            <div class="logo" @click="toCreation">
              <img
                :src="logo"
                alt="加载失败"
                style="width: 100px; height: 50px"
              />
            </div>
            <!-- <span class="name">{{ title }}</span> -->
            <div class="marL10">
              <el-button size="mini" type="primary" @click="toCreation"
                >我的商户</el-button
              >
            </div>
          </div>

          <div class="right">
            <span @click="toOrderList()">订单管理</span>
            <el-dropdown
              class="avatar-container right-menu-item hover-effect"
              trigger="click"
            >
              <div class="avatar-wrapper">
                <img :src="avatar" class="user-avatar" />
                <span style="margin-left: 5px; color: #1890ff">{{ name }}</span>
              </div>
              <el-dropdown-menu v-slot="dropdown">
                <el-dropdown-item @click.native="logout">
                  <span>退出登录</span>
                </el-dropdown-item>
                <!--                                <router-link to="/Creation/creationUserInfo">-->
                <el-dropdown-item @click.native="toUser">
                  <span>个人中心</span>
                </el-dropdown-item>
                <!--                                </router-link>-->
              </el-dropdown-menu>
              <!--                            <el-dropdown-menu v-slot="login">-->

              <!--                                <el-dropdown-item @click.native="">-->
              <!--                                    <span>个人中心</span>-->
              <!--                                </el-dropdown-item>-->
              <!--                            </el-dropdown-menu>-->
            </el-dropdown>
          </div>
        </div>
      </div>
      <div class="main">
        <router-view />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import logoImg from '@/assets/images/sunyun-logo2.svg'

export default {
  name: 'Creation',
  data () {
    return {
      logo: logoImg,
      title: '隼云科技'
    }
  },
  computed: {
    ...mapGetters(['name', 'avatar'])
  },
  methods: {
    //退出登录
    async logout () {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$store.dispatch('LogOut').then(() => {
            location.href = '/index'
          })
        })
        .catch(() => {})
    },
    async toUser () {
      this.$router.push('/creation/user')
    },
    toCreation () {
      this.$router.push('/creation/console')
    },
    toOrderList () {
      this.$router.push('/creation/orderList')
    }
  }
}
</script>

<style lang="scss" scoped>
.wap {
  height: 100vh;
  background-color: #eaeaea;
}
.creation {
  width: 100%;

  // .right-menu-item {
  //   display: inline-block;
  //   height: 100%;
  //   font-size: 18px;
  //   color: #5a5e66;
  //   vertical-align: text-bottom;

  //   &.hover-effect {
  //     cursor: pointer;
  //     transition: background 0.3s;

  //     &:hover {
  //       background: rgba(0, 0, 0, 0.025);
  //     }
  //   }
  // }

  .avatar-container {
    margin: 0 10px;

    .avatar-wrapper {
      display: flex;
      align-items: center;
      position: relative;

      .user-avatar {
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 80px;
      }

      .el-icon-caret-bottom {
        cursor: pointer;
        position: absolute;
        right: -20px;
        top: 25px;
        font-size: 12px;
      }
    }
  }

  .nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    height: 70px;
    line-height: 70px;
    position: fixed;
    top: 0;
    z-index: 10;

    .title {
      width: 1218px;
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;
        height: 100%;

        .logo {
          cursor: pointer;

          img {
            vertical-align: middle;
          }
        }
      }

      .right {
        color: #457dff;
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 100%;

        .logout {
          position: relative;
          margin-left: 20px;

          &::after {
            content: '';
            width: 1px;
            height: 20px;
            background-color: #e9edef;
            position: absolute;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  .main {
    //background-color: #f5f7fa;
  }
}

.console {
  background-color: #f5f7fa;
  overflow-y: auto;
  height: auto;

  &::-webkit-scrollbar {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
    display: none;
    /* Chrome Safari */
  }

  .main {
    //width: 70%;
    width: 1218px;
    margin: 80px auto 10px auto;
  }
}
</style>
