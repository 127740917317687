<template>
  <div class="buy-step-com" v-loading="loading">
    <div class="topBox">
      <div class="top-btns">
        <el-button size="small" v-if="[1, 2, 3].includes(step)" @click="updateStep()">上一步</el-button>
        <el-button size="small" v-if="[1, 2].includes(step)" @click="updateStep(true)">下一步</el-button>

        <el-button size="small" v-if="step === 4" @click="updateStep(true)">完成</el-button>
      </div>

      <cardTitleCom cardTitle="当前进度">
        <template slot="cardContent">
          <el-steps :active="step" align-center class="step">
            <el-step v-for="title in [
                '选择产品规格',
                '确认订单',
                '去支付',
                '支付成功',
              ]" :key="title" :title="title"></el-step>
          </el-steps>
        </template>
      </cardTitleCom>
    </div>
    <div class="bottomBox">
      <cardTitleCom cardTitle="产品规格" v-show="step === 1" v-if="!($route.query.orderNo && $route.query.money)">
        <template slot="cardContent">
          <div style="margin: 10px">
            <div class="x-w setMealBox" style="width: 100%">
              <div class="x-bc setMeal cursorP" v-for="(item, index) in product.saasProductResp" :key="index"
                :class="[index === active ? 'setMealBox-on' : '']" @click="updateForm('active', index)">
                <div>
                  <b>{{ item.productName }}</b>
                </div>
                <div>
                  <!--                  <b> ￥{{Number(getMinPay(item).pay).toFixed(2)}}/{{ getMinPay(item).text }}起</b>-->
                  <b v-if="
                      durationIndex >= 0 &&
                      item.payModelItems &&
                      item.payModelItems[durationIndex] &&
                      item.payModelItems[durationIndex].productPrice > 0
                    ">
                    ￥{{ item.payModelItems[durationIndex].productPrice }}</b>
                  <b v-else>免费</b>
                </div>
              </div>
            </div>
            <myForm label-position="left-label" v-if="showForm" v-model="productForm" ref="myForm"
              :options="productFormOption" label-width="120px" />
          </div>
        </template>
      </cardTitleCom>

      <div v-show="step === 2" v-if="orderDetail">
        <cardTitleCom cardTitle="产品规格">
          <template slot="cardContent">
            <el-descriptions :column="1" labelClassName="my-descriptions-label">
              <el-descriptions-item label="服务名称">
                {{
                  `${product.productName}（${productData[active].productName}）`
                }}
              </el-descriptions-item>
              <el-descriptions-item label="规格" v-if="
                  (productForm.isManageUsers && productForm.userNumber > 0) ||
                  (productForm.isManageShops && productForm.shopNumber > 0) ||
                  (productForm.isManagePoss && productForm.possNumber > 0)
                ">
                <span v-if="productForm.isManageUsers && productForm.userNumber > 0">
                  {{ `${productForm.userNumber}个员工账户` }}
                </span>
                <span v-if="productForm.isManageShops && productForm.shopNumber > 0">
                  <span v-if="
                      productForm.isManageUsers && productForm.userNumber > 0
                    ">+</span>
                  {{ `${productForm.shopNumber}个门店数` }}
                </span>
                <span v-if="productForm.isManagePoss && productForm.possNumber > 0">
                  <span v-if="
                      (productForm.isManageUsers &&
                        productForm.userNumber > 0) ||
                      (productForm.isManageShops && productForm.shopNumber > 0)
                    ">+</span>
                  {{ `${productForm.possNumber}个站点数` }}
                </span>
              </el-descriptions-item>
              <el-descriptions-item label="有效期">{{
                orderDetail.unit
              }}</el-descriptions-item>
              <el-descriptions-item label="原价">{{ Number(orderDetail.oldOrderMoney).toFixed(2) }}元
              </el-descriptions-item>
              <el-descriptions-item label="优惠券">无可用优惠券</el-descriptions-item>
              <el-descriptions-item label="合计">{{ Number(orderDetail.orderMoney).toFixed(2) }}元
              </el-descriptions-item>
              <el-descriptions-item label="联系人">{{
                orderDetail.linkMan
              }}</el-descriptions-item>
              <el-descriptions-item label="联系人电话">{{
                orderDetail.linkManTel
              }}</el-descriptions-item>
            </el-descriptions>
          </template>
        </cardTitleCom>
        <cardTitleCom cardTitle="用户须知">
          <template slot="cardContent">
            <el-checkbox v-model="checked"></el-checkbox>
            <span style="font-size: 12px; margin-left: 5px; line-height: 23px">
              我已阅读并同意
              <el-link type="primary" style="font-size: 12px">《合同条款》</el-link>
            </span>
            <div style="height: 80px"></div>
          </template>
        </cardTitleCom>
      </div>

      <cardTitleCom cardTitle="支付方式" v-show="step === 3"
        v-if="orderDetail || ($route.query.orderNo && $route.query.money)">
        <template slot="cardContent">
          <div class="pay-code">
            <div style="line-height: 30px; padding: 15px 0; font-size: 16px">
              应付金额：
              <span style="font-size: 30px; color: #ff5b28">{{
                  orderDetail ? orderDetail.orderMoney : $route.query.money
                }}元</span>
            </div>
            <img :src="QRImgUrl" class="QRImgUrl" style="width: 180px; height: 180px" />
            <div style="
                color: #666666;
                font-size: 14px;
                padding: 15px 0;
                display: flex;
                algin-item: center;
                justify-content: center;
              ">
              <el-image :src="require('@/assets/images/weixinpay.png')" class="icon" />
              <div>微信支付</div>
              <el-image :src="require('@/assets/images/zhifubaopay.png')" class="icon" />
              <div>支付宝支付</div>
              <el-image :src="require('@/assets/images/yunshanpay.png')" class="icon" />
              <div>云闪付</div>
            </div>
            <div style="color: #666666; font-size: 14px; padding: 15px 0">
              温馨提示：产品一经激活启用，非产品原因不允许退货退款
            </div>
          </div>
        </template>
      </cardTitleCom>

      <cardTitleCom cardTitle="支付方式" v-if="step === 4">
        <div class="pay-code" slot="cardContent">
          <i class="el-icon-circle-check" style="color: #28ac3f; font-size: 48px; margin: 15px"></i>
          支付成功
          <div>
            <el-link type="primary" @click="toSP()">倒计时{{ timerIndex }}秒,进入商户</el-link>
          </div>
        </div>
      </cardTitleCom>
    </div>
  </div>
</template>
<script>
import { getAllRegion } from "@/api/goods/region";
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import myForm from "@/components/my-form";
import { createTenant, getOrderState, orderPay } from "@/api/creation/index.js";
import { getOnlineProductDetail } from "@/api/tenant/product/saas.js"; //接口api
import { switchTenant } from "@/api/system/tenant";
import { mapMutations } from "vuex";
export default {
  name: "buyStep",
  components: { swiper, swiperSlide, cardTitleCom, myForm },
  props: ["product", "inProductId", "sign"],
  data() {
    return {
      // getMinPay: data => {
      //   if (!this.showForm || !this.yearsData) {
      //     return { pay: '', text: '' }
      //   }
      //   let yearsData = data.payModelItems.map(x => {
      //     const item = this.yearsData.find(y => y.dictValue === x.payModelType)
      //     let payModelPrice = 0
      //     let [{ shopsPrice = 0 } = {}] = deepCopy(
      //       x.saasProductShopsPrices
      //     ).sort((a, b) => b.shopsPrice - a.shopsPrice) // 门店最低单价
      //     let [{ usersPrice = 0 } = {}] = deepCopy(
      //       x.saasProductUsersPrices
      //     ).sort((a, b) => b.usersPrice - a.usersPrice) // 门店最低单价

      //     shopsPrice = x.initializeShops * x.initializeShopsPrice + shopsPrice
      //     usersPrice = x.initializeUsers * x.initializeUsersPrice + usersPrice

      //     payModelPrice = shopsPrice + usersPrice + data.productPrice
      //     // 获取最小价格
      //     return { payModelPrice, label: item?.dictLabel || '' }
      //   })

      //   // 取折扣后金额
      //   const priceArr = yearsData.sort(
      //     (a, b) => a.payModelPrice - b.payModelPrice
      //   )
      //   // 取一个最低价显示
      //   return {
      //     pay: priceArr[0]?.payModelPrice || 0.0,
      //     text: priceArr[0]?.label || '年'
      //   }
      // },
      active: 0, //
      dateActive: 0, // 时长
      showForm: false,
      QRImgUrl: "",
      checked: false,
      loading: true,
      step: 1,
      payImg: "",
      productFormOption: [],
      productForm: {},
      dictRes: [],
      productData: [],
      timeing: 0, // 监控之间
      timer: null, // 支付状态计时器
      yearsData: [],
      createdData: null,
      timeA: null, // 定时器 - 定时跳转
      timerIndex: 10, // 倒计时进入首页
      durationIndex: undefined,
      provinceList: [],
    };
  },
  computed: {
    // 计算用户订详情
    orderDetail: function () {
      const oneKey = "duration";
      const twoKey = "userNumber";
      const threeKey = "shopNumber";
      const possKey = "possNumber";

      const oneData = this.yearsData;
      const val = this.productForm;

      const {
        label,
        productPrice,
        productOldPrice,
        productId: pdID,
        payModelId: pmID,
        initializeUsers,
        initializeShops,
        initializePoss,
        saasProductUsersPrices,
        saasProductShopsPrices,
        saasProductPossPrices,
        initializeShopsPrice,
        initializeUsersPrice,
        initializePossPrice,
        isManageUsers,
        isManageShops,
        isManagePoss,
      } = oneData?.find((x) => x.value === val[oneKey]) || {};
      this.productForm.money = "0" + "元";
      if (!this.productForm[oneKey] || !pdID) {
        return null;
      }
      let money = 0; // 订单价格
      let oldMoney = 0; // 订单原价 前端用
      let unit = ""; // 单位 前端用
      let payModelId = null; // 包年付费方式ID
      let productId = null; // 购买产品ID
      let shopsPriceId = null; // 门店数价格ID
      let usersPriceId = null; // 用户数价格ID
      let possPriceId = null; // 站点数价格ID

      let usersOldMoney = 0; // 用户原价  前端用
      let usersMoney = 0; // 用户支付价格  前端用
      let showUsersPrice = 0; // 用户展示的单价

      let shopsOldMoney = 0; // 门店原价  前端用
      let shopsMoney = 0; // 门店支付价格  前端用
      let showShopPrice = 0; // 门店展示的单价

      let possOldMoney = 0; // 站点原价  前端用
      let possMoney = 0; // 站点支付价格  前端用
      let showPossPrice = 0; // 站点展示的单价

      // 购买时常价格

      if (label && pdID) {
        unit = label;
        productId = pdID;
        payModelId = pmID;
      } else {
        return null;
      }

      // 购买人数价格
      if (saasProductUsersPrices?.[0]?.usersPrice && isManageUsers) {
        let twoData = saasProductUsersPrices?.sort?.(
          (a, b) => b.users - a.users
        );
        const {
          usersPrice = 0,
          usersOldPrice = 0,
          usersPriceId: upId,
        } = twoData?.find((x) => val[twoKey] - initializeUsers >= x.users) ||
          {};

        usersOldMoney =
          usersOldPrice * (this.productForm[twoKey] - initializeUsers); // 用户原价  前端用
        usersMoney = usersPrice * (this.productForm[twoKey] - initializeUsers); // 用户支付价格  前端用
        showUsersPrice = usersPrice; // 用来展示的用户单价
        money = usersMoney + initializeUsersPrice * initializeUsers;

        oldMoney = usersOldMoney + initializeUsersPrice * initializeUsers;
        usersPriceId = upId;
      }

      // 购买门店价格
      if (saasProductShopsPrices?.[0]?.shopsPrice && isManageShops) {
        let threeData = saasProductShopsPrices?.sort?.(
          (a, b) => b.shops - a.shops
        );
        const {
          shopsPrice = 0,
          shopsOldPrice = 0,
          shopsPriceId: spId,
        } = threeData?.find(
          (x) => val[threeKey] - initializeShops >= x.shops
        ) || {};
        shopsOldMoney =
          shopsOldPrice * (this.productForm[threeKey] - initializeShops); // 用户原价  前端用
        shopsMoney =
          shopsPrice * (this.productForm[threeKey] - initializeShops); // 用户支付价格  前端用
        showShopPrice = shopsPrice; // 用于显示提示

        money = money + shopsMoney + initializeShopsPrice * initializeShops;

        oldMoney =
          oldMoney + shopsOldMoney + initializeShopsPrice * initializeShops;
        shopsPriceId = spId;
      }

      // 购买站点价格
      if (saasProductPossPrices?.[0]?.possPrice && isManagePoss) {
        let threeData = saasProductPossPrices?.sort?.(
          (a, b) => b.poss - a.poss
        );
        const {
          possPrice = 0,
          possOldPrice = 0,
          possPriceId: ppId,
        } = threeData?.find((x) => val[possKey] - initializePoss >= x.poss) ||
          {};
        possOldMoney =
          possOldPrice * (this.productForm[possKey] - initializePoss); // 用户原价  前端用
        possMoney = possPrice * (this.productForm[possKey] - initializePoss); // 用户支付价格  前端用
        showPossPrice = possPrice; // 用于显示提示
        money = money + possMoney + initializePossPrice * initializePoss;
        oldMoney =
          oldMoney + possOldMoney + initializePossPrice * initializePoss;
        possPriceId = ppId;
      }

      // 去下浮点
      oldMoney = productOldPrice + oldMoney;
      money = money + Number(productPrice);
      money = money % 1 === 0 ? money : Number(money.toFixed(2));
      oldMoney = oldMoney % 1 === 0 ? oldMoney : Number(oldMoney.toFixed(2));

      this.productForm.money = money + "元";

      const obj = {
        ...val,
        orderMoney: money,
        oldOrderMoney: oldMoney,
        unit,
        payModelId,
        productId,
        shopsPriceId,
        usersPriceId,
        possPriceId,
        shopsQty: val[threeKey],
        usersQty: val[twoKey],
        possQty: val[possKey],
        usersOldMoney,
        usersMoney,
        shopsOldMoney,
        shopsMoney,
        possOldMoney,
        possMoney,
        initializeUsers,
        initializeShops,
        initializePoss,
        showUsersPrice,
        showShopPrice,
        showPossPrice,
        linkManTel: String(val.linkManTel),
      };

      this.QRImgUrl = "";

      return obj;
    },
  },
  async mounted() {
    if (this.$route.query.orderNo && this.$route.query.money) {
      this.step = 3;
      const imgRes = await orderPay({
        ordNo: this.$route.query.orderNo,
        amt: this.$route.query.money,
        subject: "创建商户",
      });
      this.QRImgUrl = imgRes.data;
      const clearTimer = () => clearInterval(this.timer);
      // 每秒检测一次
      this.timer = setInterval(async () => {
        // 一分钟计时
        if (this.timeing <= 60) {
          const res = await getOrderState({ ordNo: this.$route.query.orderNo });
          if (res?.data) {
            // 支付成功
            clearTimer();
            this.createdData = {
              productId: res.data.productId,
              tenantId: res.data.tenantId,
            };
            this.step = 4;
          }
        } else {
          clearTimer();
          this.$message.warning("支付超时！");
          this.$emit("update:active", 1);
        }
        this.loading = false;
        this.timeing = this.timeing + 1;
      }, 1000);
    } else {
      try {
        this.active = this.sign;
        const { data } = await this.getDicts("product_years_package_mode");
        this.dictRes = data;
        this.productData = await Promise.all(
          this.product.saasProductResp.map(async (x) => {
            const { data } = await getOnlineProductDetail(x.productId);
            return data;
          })
        );
        this.updateForm();
        this.loading = false;
      } catch (error) { }
    }
  },
  watch: {
    "productForm.duration": {
      handler(newDuration) {
        if (this.showForm && newDuration) {
          this.durationIndex = this.productData[
            this.active
          ].payModelItems.findIndex((x) => x.payModelType === newDuration);
          this.updateForm(
            "duration",
            this.productData[this.active].payModelItems.findIndex(
              (x) => x.payModelType === newDuration
            )
          );
        }
      },
    },
    "productForm.tenantName": {
      handler(newTenantName) {
        this.$set(this.productForm, "simpleName", newTenantName);
      },
    },
    step(nVal) {
      if (nVal === 4) {
        let that = this;
        this.timeA = setInterval(() => {
          that.timerIndex--;
          console.log(that.timerIndex);
          if (that.timerIndex === 0) {
            that.$tab.closeAllPage();
            clearInterval(that.timeA);
            that.timeA = null;
            if (this.createdData?.tenantId && this.createdData?.productId) {
              switchTenant({ tenantId: this.createdData.tenantId }).then(
                (res) => {
                  this.$store.commit(
                    "SET_PRODUCTID",
                    this.createdData.productId
                  );
                  this.$store.commit("SET_TENANTID", this.createdData.tenantId);
                  this.$store.commit("RESET_ROUTES");
                  this.$tab.closeAllPage();
                  this.$router.push("/index");
                }
              );
            } else {
              this.$router.push("/creation/orderList");
            }
          }
        }, 1000);
      }
    },
  },
  methods: {
    ...mapMutations("tagsView", ["DEL_VISITED_VIEW"]),

    async updateForm(key, value) {
      //商户基础价格下标
      if (key == "duration" && value >= 0) {
        this.durationIndex = value;
      } else {
        this.durationIndex = undefined;
      }

      if (key) {
        this[key] = value;
      }
      const isDuration = key === "duration";
      try {
        this.showForm = false;
        const { payModelItems, maxUsers, maxShops, maxPoss } =
          this.productData[this.active];

        if (!isDuration) {
          this.yearsData = payModelItems.map((x) => {
            const item = this.dictRes.find(
              (y) => y.dictValue === x.payModelType
            );
            return {
              ...x,
              value: x.payModelType,
              label: item.dictLabel,
            };
          });
        }
        const {
          initializeShops,
          initializeUsers,
          initializeShopsPrice,
          initializeUsersPrice,
          saasProductUsersPrices,
          saasProductShopsPrices,
          isManageShops,
          isManageUsers,
          value: durationValue,
          isManagePoss,
          saasProductPossPrices,
          initializePoss,
          initializePossPrice,
        } = this.yearsData[isDuration ? value : 0];
        const userShops = [
          // saasProductUsersPrices?.[0]?.usersPrice && isManageUsers ? {
          isManageUsers
            ? {
              model: "userNumber",
              type: "input-number",
              label: "在线用户数",
              // disabled: isManageUsers !== 1,
              append: {
                type: "xyt-users",
                show: true,
                isInitial: true,
                getPrice: () => ({
                  unit: this.orderDetail.unit,
                  showUsersPrice: this.orderDetail.showUsersPrice,
                  orderMoney:
                    this.orderDetail.usersMoney +
                    initializeUsers * initializeUsersPrice,
                  oldOrderMoney:
                    this.orderDetail.usersOldMoney >
                      this.orderDetail.usersMoney
                      ? this.orderDetail.usersOldMoney +
                      initializeUsers * initializeUsersPrice
                      : "",
                }),
              },
              data: saasProductUsersPrices,
              min: initializeUsers > 0 ? initializeUsers : 1, //最小用户数
              max: maxUsers,
              initializeUsers: initializeUsers, //用户数
              initializeUsersPrice: initializeUsersPrice,
              precision: 0,
              rules: [
                {
                  required: true,
                  message: "请输入在线用户数",
                  trigger: ["blur", "change"],
                },
              ],
            }
            : null,
          // saasProductShopsPrices?.[0]?.shopsPrice && isManageShops ? {
          isManageShops
            ? {
              model: "shopNumber",
              type: "input-number",
              label: "门店个数",
              data: saasProductShopsPrices,
              min: initializeShops > 0 ? initializeShops : 1, // 为0的时候，默认1个，大于0时则为初始用户
              max: maxShops,
              show: true,
              // disabled: isManageShops !== 1,
              initializeShops: initializeShops, // 为0的时候，默认1个，大于0时则为初始用户
              initializeShopsPrice: initializeShopsPrice,
              precision: 0,
              append: {
                type: "xyt-users",
                show: true,
                isInitial: true,
                getPrice: () => ({
                  showShopPrice: this.orderDetail.showShopPrice,
                  unit: this.orderDetail.unit,
                  orderMoney:
                    this.orderDetail.shopsMoney +
                    initializeShops * initializeShopsPrice,
                  oldOrderMoney:
                    this.orderDetail.shopsOldMoney >
                      this.orderDetail.shopsMoney
                      ? this.orderDetail.shopsOldMoney +
                      initializeShops * initializeShopsPrice
                      : "",
                  discounted: undefined,
                }),
              },
              rules: [
                {
                  required: true,
                  message: "请输入门店个数",
                  trigger: ["blur", "change"],
                },
              ],
            }
            : null,
          isManagePoss
            ? {
              model: "possNumber",
              type: "input-number",
              label: "站点个数",
              data: saasProductPossPrices,
              min: initializePoss > 0 ? initializePoss : 1, // 为0的时候，默认1个，大于0时则为初始用户
              max: maxPoss,
              show: true,
              // disabled: isManageShops !== 1,
              initializePoss: initializePoss, // 为0的时候，默认1个，大于0时则为初始用户
              initializePossPrice: initializePossPrice,
              precision: 0,
              append: {
                type: "xyt-users",
                show: true,
                isInitial: true,
                getPrice: () => ({
                  showPossPrice: this.orderDetail.showPossPrice,
                  unit: this.orderDetail.unit,
                  orderMoney:
                    this.orderDetail.possMoney +
                    initializePoss * initializePossPrice,
                  oldOrderMoney:
                    this.orderDetail.possOldMoney > this.orderDetail.possMoney
                      ? this.orderDetail.possOldMoney +
                      initializePoss * initializePossPrice
                      : "",
                  discounted: undefined,
                }),
              },
              rules: [
                {
                  required: true,
                  message: "请输入站点个数",
                  trigger: ["blur", "change"],
                },
              ],
            }
            : null,
        ];
        const response = await getAllRegion();
        this.provinceList = response.data;
        this.productFormOption = [
          {
            model: "duration",
            type: "radio-button",
            label: "购买时长",
            rules: [
              {
                required: true,
                message: "请选择购买时长",
                trigger: ["blur", "change"],
              },
            ],
            optionData: this.yearsData,
          },
          ...userShops.filter((x) => x),
          {
            model: "money",
            type: "text-append",
            label: "合计",
            readonly: true,
          },
          {
            type: "divider",
          },
          {
            prop: "logoUrl",
            model: "packageFile",
            type: "upload-one",
            label: "商户logo",
            style: { width: 370 },
            option: {
              listType: "img",
              type: ["jpg", "png"],
              size: "2 mb",
              autoUpload: true,
            },
          },
          {
            model: "tenantName",
            type: "input",
            style: { width: 370 },
            label: "商户名称",
            rules: [
              {
                required: true,
                message: "请输入商户名称",
                trigger: ["blur", "change"],
              },
            ],
          },
          {
            model: "simpleName",
            type: "input",
            style: { width: 370 },
            label: "商户简称",
          },
          {
            model: "regionId",
            type: "cascader",
            style: { width: 370 },
            option: {
              data: this.provinceList,
              value: "id",
              label: "label",
              emitPath: false,
              clearable: true,
              filterable: true,
            },
            label: "商户地址",
            rules: [
              {
                required: true,
                message: "请选择商户地址",
                trigger: ["blur", "change"],
              },
            ],
          },
          {
            model: "address",
            type: "input",
            style: { width: 370 },
            label: "详细地址",
          },
          {
            model: "linkMan",
            type: "input",
            style: { width: 370 },
            label: "联系人",
            rules: [
              {
                required: true,
                message: "请输入联系人",
                trigger: ["blur", "change"],
              },
            ],
          },
          {
            model: "linkManTel",
            type: "tel",
            style: { width: 370 },
            label: "联系人电话",
            tip: "请输入联系人电话",
            rules: [
              {
                required: true,
                message: "请输入联系人电话",
                trigger: ["blur", "change"],
              },
              {
                pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                message: "请输入正确的手机号码",
                trigger: "blur",
              },
            ],
          },
        ];
        this.productForm.linkMan = this.productForm.linkMan
          ? this.productForm.linkMan
          : this.$store.state.user.userinfo.nickName;
        this.productForm.linkManTel = this.productForm.linkManTel
          ? this.productForm.linkManTel
          : this.$store.state.user.userinfo.telephone;
        this.productForm = {
          ...this.productForm,
          duration: durationValue, // 购买时长
          isManageUsers,
          isManageShops,
          isManagePoss,
          userNumber: isManageUsers && !initializeUsers ? 1 : initializeUsers,
          shopNumber: isManageShops && !initializeShops ? 1 : initializeShops,
          possNumber: isManagePoss && !initializePoss ? 1 : initializePoss,
          // tenantName: isDuration ? this.productForm.tenantName : '',
          // simpleName: isDuration ? this.productForm.simpleName : '',

          // money: '0'
        };
        try {
          this.$refs.myForm.$refs.value.clearValidate();
        } catch (error) { }
        this.showForm = true;
      } catch (error) {
        console.log("Errrrrr", error);
      }
    },
    async updateStep(next = false) {
      const clearTimer = () => clearInterval(this.timer);
      if (this.step === 1 && !next) {
        return this.$emit("update:active", 1);
        // payModelType
      }

      if (this.step === 4 && next) {
        const find = this.$store.getters.visitedViews.find(
          (item) => item.path === this.$route.path
        );
        this.DEL_VISITED_VIEW(find);
        return await this.$router.push({
          path: "/creation/console",
        });
      }

      if (this.step === 3 && !next) {
        return await this.$router.push({
          path: "/creation/orderList",
        });
      }

      if (this.step === 1) {
        try {
          await this.$refs.myForm.$refs.value.validate();
        } catch (error) {
          return this.$message.error(Object.values(error)[0][0].message);
        }
      } else if (this.step === 2 && !this.checked && next) {
        return this.$message.warning("请阅读并同意合同条款");
      } else if (this.step === 2 && next) {
        const loading = this.$loading({
          lock: true,
          text: "订单提交中...",
          target: document.querySelector(".app-main"),
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.0)",
        });
        // 2 创建订单
        const {
          oldOrderMoney = 0,
          orderMoney = 0,
          payModelId = 0,
          productId = 0,
          shopsPriceId = "",
          usersPriceId = "",
          possPriceId = "",
          regionId,
          shopsQty = 0,
          usersQty = 0,
          possQty = 0,
          linkMan = "",
          linkManTel = "",
          tenantName = "",
          simpleName = "",
          logoUrl = "",
          address = "",
          isManageShops = true,
          isManageUsers = true,
          isManagePoss = true,
        } = this.orderDetail;

        // 加上时间戳 防止重复
        // orderNo = orderNo + Math.round(new Date())
        console.log("开始2");

        try {
          console.log("开始2.5");
          // 创建商户
          const res = await createTenant({
            oldOrderMoney,
            contactMan: linkMan,
            telephone: linkManTel,
            orderMoney,
            payModelId,
            productId,
            shopsPriceId,
            shopsQty: isManageShops ? shopsQty : 0,
            usersPriceId,
            usersQty: isManageUsers ? usersQty : 0,
            possPriceId,
            possQty: isManagePoss ? possQty : 0,
            tenantName,
            simpleName,
            logoUrl,
            address,
            regionId,
          });
          let orderNo = res.data;
          this.createdData = {};
          // 3 获取支付码
          const imgRes = await orderPay({
            ordNo: orderNo,
            amt: orderMoney,
            subject: "创建商户",
          });
          this.QRImgUrl = imgRes.data;
          // 每秒检测一次
          this.timer = setInterval(async () => {
            // 一分钟计时
            if (this.timeing <= 60) {
              const res = await getOrderState({
                ordNo: orderNo,
              });
              if (res?.data) {
                // 支付成功
                clearTimer();
                this.createdData = {
                  productId: res.data.productId,
                  tenantId: res.data.tenantId,
                };
                this.step = 4;
              }
            } else {
              clearTimer();
              this.$message.warning("支付超时！");
              this.$emit("update:active", 1);
            }
            this.timeing = this.timeing + 1;
          }, 1000);
          loading.close();
        } catch (error) {
          // this.$message.error(error.msg);
          return loading.close();
        }
      }
      this.step = this.step + (next ? 1 : -1);
      if (this.step !== 3) {
        clearTimer();
      }
    },
    toSP() {
      // const find = this.$store.getters.visitedViews.find(
      //   item => item.path === this.$route.path
      // )
      // this.DEL_VISITED_VIEW(find)
      if (this.createdData?.tenantId && this.createdData?.productId) {
        switchTenant({ tenantId: this.createdData.tenantId }).then((res) => {
          this.$store.commit("SET_PRODUCTID", this.createdData.productId);
          this.$store.commit("SET_TENANTID", this.createdData.tenantId);
          this.$store.commit("RESET_ROUTES");
          this.$tab.closeAllPage();
          this.$router.push("/index");
        });
      } else {
        this.$router.push("/creation/orderList");
      }
    },
  },
  destroyed() {
    clearTimeout(this.timeA);
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>
<style lang="scss" scoped>
.buy-step-com {
  width: 1218px;
  margin: 0px auto;
  //padding: 10px 0;
  background-color: #f4f6f9;

  ::v-deep .my-descriptions-label {
    width: 100px;
    text-align: right;
    display: block;
  }

  ::v-deep .is-process {
    color: #c0c4c0 !important;

    .el-step__icon {
      border: 2px solid #c0c4c0 !important;
    }
  }

  .topBox {
    width: 1218px;
    position: fixed;
    background-color: #eaeaea;
    z-index: 666;
    padding-top: 10px;
  }

  .bottomBox {
    padding-top: 173px;
  }

  .top-btns {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
    //margin-top: 10px;
  }

  .pay-code {
    height: calc(100vh - 250px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;

    .icon {
      width: 20px;
      height: 20px;
      margin-left: 15px;
      margin-right: 3px;
    }

    .QRImgUrl {
      padding: 20px;
      border-radius: 30px;
      overflow: hidden;
      border: 2px solid #b3d9e9;
    }
  }

  .productCardBox {
    border: 1px solid #dedede;
    width: 180px;
    height: 180px;
    border-radius: 10px;
    margin-right: 10px;

    cursor: pointer;

    &:hover {
      border: 1px solid #3490ff;
      box-shadow: 0px 3px 20px 1px rgba(51, 142, 255, 0.15);
    }

    .productCard {
      width: 100%;
      height: 140px;
      text-align: center;

      .priceL {
        font-size: 18px;
      }

      .price {
        font-size: 36px;
        font-weight: bold;
      }

      .priceR {
        font-size: 14px;
        color: #666666;
      }
    }

    .explain {
      font-size: 12px;
      width: 100%;
      height: 40px;
      border-top: 1px solid #dedede;
      bottom: 0;
      padding: 0 20px;
    }
  }

  .productCardBox-on {
    background-color: #edf6ff;
    border: 1px solid #3490ff;
    box-shadow: 0px 3px 20px 1px rgba(51, 142, 255, 0.15);
  }
}

.setMealBox {
  width: 80%;
  padding: 10px 0;
  //border: 1px solid #dedede;
  border-radius: 5px;
}

.setMeal {
  min-width: 240px;
  padding: 8px 10px;
  //border-right: 1px solid #dedede;
  border: 1px solid #dedede;

  &:hover {
    border: 1px solid #3490ff;
    box-shadow: 0px 3px 20px 1px rgba(51, 142, 255, 0.15);
  }
}

.setMealBox-on {
  background-color: #edf6ff;
  border: 1px solid #3490ff;
  box-shadow: 0px 3px 20px 1px rgba(51, 142, 255, 0.15);
}
</style>
