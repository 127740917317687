<template>
  <div class="create">
  <div class="create-step" v-if="show">
    <div class="top">
      <el-steps :active="active" finish-status="finish" :space="1000">
        <el-step title="选择产品"></el-step>
        <el-step title="选择产品版本"></el-step>
        <el-step title="填写商户信息"></el-step>
        <el-step title="创建完成"></el-step>
      </el-steps>
      <!-- <el-button style="margin-top: 12px;" @click="next">下一步</el-button> -->
    </div>
    <div class="bottom">
      <div class="step1" v-show="current === 0">
        <div class="choose">
          <i class="el-icon-arrow-left" @click="to('choose-left')" v-show="pageShow('choose')"></i>
          <div
            class="choose-item"
            v-for="(item, index) in productArr"
            :key="index"
          >
            <img
              :src="item.logoUrl"
              alt="加载失败"
              style="width:100px;height:100px"
            />
            <div class="choose-title">{{ item.productName }}</div>
            <div class="choose-btn" @click="next('choose', item.productId)" >
              创建
            </div>
          </div>
          <i class="el-icon-arrow-right" @click="to('choose-right')" v-show="pageShow('choose')"></i>
        </div>
      </div>
      <div class="step2" v-show="current === 1">
        <div class="product">
          <i class="el-icon-arrow-left" @click="to('product-left')" v-show="pageShow('product')" ></i>

          <div
            class="product-item"
            v-for="(item, index) in showVersions"
            :key="index"
          >
            <div class="versions">
              <span>{{ item.versionName }}</span>
            </div>
            <div class="price">
              <div>{{ item.productPrice }}</div>
              <button @click="next('product',item.versionId)">{{ item.serviceTime }}</button>
            </div>
            <div class="intro">
              <div>{{ item.versionIntroduction }}</div>
            </div>
          </div>
          <i class="el-icon-arrow-right" @click="to('product-right')"  v-show="pageShow('product')"></i>
        </div>
      </div>
      <div class="step3" v-show="current === 2">
        <div class="write">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="商户LOGO" prop="logoUrl">
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="handleLogoSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="ruleForm.logoUrl"
                  :src="ruleForm.logoUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>

            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="商户名称" prop="tenantName">
                  <el-input v-model="ruleForm.tenantName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="商户简称" prop="simpleName">
                  <el-input v-model="ruleForm.simpleName"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="商户电话" prop="phone">
                  <el-input v-model="ruleForm.phone"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="商户地址" prop="address">
                  <el-input v-model="ruleForm.address"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="联系人姓名" prop="contactMan">
                  <el-input v-model="ruleForm.contactMan" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="联系人电话"
                  prop="telephone"
                  label-position="top"
                >
                  <el-input v-model="ruleForm.telephone" @blur="verifyPhone"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item
              label="您的所属行业是?"
              prop="belongSector"
              label-width="130px"
            >
              <el-radio-group v-model="ruleForm.belongSector" size="medium" style="marginLeft:20px">
                <el-radio border :label="dict.label" v-for="dict in dict.type.belong_sector" :key="dict.value"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="您的团队有多少员工?"
              prop="shaffQuantity"
              label-width="150px"
            >
              <el-radio-group v-model="ruleForm.shaffQuantity" size="medium">
                <el-radio border :label="dict.label" v-for="dict in dict.type.staff_quantity" :key="dict.value"></el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- <div class="speical-form">
              <p class="label">您的所属行业是?</p>
              <div>
                <span
                  v-for="(item, index) in typeArr"
                  :key="index"
                  @click="possess(item)"
                  >{{ item }}</span
                >
              </div>
              <p class="label">您的团队有多少员?</p>
              <span
                v-for="(item, index) in typeArr"
                :key="index"
                @click="possess(item)"
                >{{ item }}</span
              >
            </div> -->
          </el-form>
          <div class="next-step">
            <button class="btn" @click="next('write')">下一步</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <product-service v-else :productDetail="productDetail" :orderDetail="orderDetail" @click="submitOrder" :orderShow="orderShow"></product-service>
</div>
</template>

<script>
import { ProductListAPI } from '@/api/system/product'
import {createTenantAPI, soleTelephoneAPI } from '@/api/system/tenant'
import ProductService from '../components/ProductService.vue'
export default {
  name: 'CreateStep',
  dicts: ['belong_sector', 'staff_quantity'],
  components:{
    ProductService
  },
  data () {
    let setTelephone=(role,value,callback)=>{
      if(!this.solePhone){
        callback(new Error('联系人电话(手机号码)已创建过,请重新输入'));
      }else{
        callback()
      }
    }
    return {
      baseUrl: process.env.VUE_APP_BASE_API,
      uploadUrl: process.env.VUE_APP_BASE_API + '/api/open/oss/file/upload',
      //切换当前
      current: 0,
      //进度条进度
      active: 1,
      //创建商户信息表单
      ruleForm: {
        contactMan:null,
        logoUrl:null,
        phone:null,
        versionId:null,
        telephone:null,
        tenantName:null,
        address:null,
        belongSector:null,
        simpleName:null,
        staffQuantity:null
      },
      //表单校验规则
      rules: {
        contactMan: [
          { required: true, message: '请输入联系人名称', trigger: 'blur' }
        ],
        phone:[
          { required: true, message: '请输入商户电话', trigger: 'blur' }
        ],
        telephone:[
          { required: true, message: '请输入联系人电话(手机号码)', trigger: 'blur' },
          { validator: setTelephone}
        ],
        tenantName:[
          { required: true, message: '请输入商户名称', trigger: 'blur' }
        ]
      },
      //商户logo
      logoUrl: '',
      dialogImageUrl: '',
      dialogVisible: false,
      //行业绑定值
      radio1: '上海',
      //行业类型
      typeArr: ['上海', '深圳', '广州', '北京', '南京'],
      //产品列表
      productArr: [],
      //产品总类
      productTotal: '',
      //产品列表页码
      pageNum: 1,
      //产品列表页容量
      pageSize: 3,
       //产品版本列表页码
       pageIndex: 1,
      //产品版本列表页容量
       size: 3,
       //产品版本总数量
       versionsTotal: 0,
       //当前展示的数据
      showVersions: [],
      //产品版本列表
      versionsArr: [],
      //产品详情
      productDetail:{},
      //切换显示
      show:'true',
      //发送订单状态
      loading:true,
      //订单详情
      orderDetail:{},
      //订单详情显示
      orderShow:0,
      //判断联系人手机号是否唯一
      solePhone:true
    }
  },
  created () {
    this.ProductList()
  },
  methods: {
    //提交订单
   async submitOrder(id){
    if(!this.loading){
      return this.$message.error('正在提交订单中~')
    }
      this.ruleForm.versionId=id
     try{
      this.loading=false
      const res=await createTenantAPI(this.ruleForm)
      this.orderDetail=res.data
      this.$message.success('提交订单成功!')
      this.orderShow=1
     }catch{

     }
     this.loading=true
    },
    //产品列表轮播
    to (next) {
      if(next==='choose-left'||next==='choose-right'){
        const page = Math.ceil(this.productTotal / this.pageSize)
      if (next === 'choose-left') {
        this.pageNum = this.pageNum-- <= 1 ? page : this.pageNum--
      } else {
        this.pageNum = this.pageNum++ >= page ? 1 : this.pageNum++
      }
      this.ProductList()
      }else{
        const page = Math.ceil(this.versionsTotal / this.size)
        if (next === 'product-left') {
        this.pageIndex = this.pageIndex-- <= 1 ? page : this.pageIndex--
      } else {
        this.pageIndex = this.pageIndex++ >= page ? 1 : this.pageIndex++
      }
      this.getShowVersions()
      }
    },
    //分类是否显示
    pageShow(name){
      if(name==='choose'){
      const page = Math.ceil(this.productTotal / this.pageSize)
      return page<=1?false:true
      }else{
        const page = Math.ceil(this.versionsTotal / this.size)
        return page<=1?false:true
      }

    },

    /** 图片上传之前的尺寸校验 */
    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      return isLt2M
    },

    /** 上传LOGO图片方式 */
    handleLogoSuccess (res, file) {
      this.ruleForm.logoUrl = file.response.data.accessUrl
      this.$forceUpdate()
    },

    //获取产品列表
    async ProductList () {
      const res = await ProductListAPI({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })
      this.productArr = res.rows
      this.productTotal = res.total
    },

    //下一步骤
   async next (type, id) {
      if (type === 'write') {
        try{
          await this.$refs.ruleForm.validate()
          this.show=!this.show
        }catch{
          return
        }
      } else if (type === 'choose') {
        const choose = this.productArr.find(v => v.productId === id)
        this.versionsArr = choose.versions
        this.versionsTotal=choose.versions.length
        this.getShowVersions()
      }else{
        this.productDetail=this.versionsArr.find(v=>v.versionId===id)
        this.versionId=id
      }
      this.active = this.active + 1 <= 3 ? this.active + 1 : 1
      this.current++
    },
     //产品版本分页功能
     getShowVersions() {
      // 5. 获取截取开始索引
      let begin = (this.pageIndex - 1) * this.size;
      // 6. 获取截取结束索引
      let end = this.pageIndex * this.size;
      // 7. 通过索引去截取，从而展示
      this.showVersions = this.versionsArr.slice(begin, end);
    },
    //表单所属类别事件
   /*  possess (item) {
      console.log(item)
    } */
    //校验创建商户信息手机号唯一
   async verifyPhone () {
     if(!this.ruleForm.telephone) return
     this.solePhone = true
      soleTelephoneAPI(this.ruleForm.telephone).then(response => {
        if(!response.enable){
          this.solePhone = response.enable
          this.$refs.ruleForm.validate()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.create{
.create-step {
  .top {
    margin-bottom: 20px;
    width: 100%;
    height: 100px;
    background-color: #ffffff;
    ::v-deep.el-steps {
      padding: 30px 0 10px 200px;
    }
  }
  .bottom {
    // height: 100vh;
    background-color: #ffffff;
    .step1 {
      .choose {
        display: flex;
        justify-content: space-around;
        padding: 30px;
        align-items: center;
        i {
          font-size: 40px;
          cursor: pointer;
        }
        .choose-item {
          width: 30%;
          border: 1px solid #dcdfe6;
          border-radius: 8px;
          height: 650px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            margin-top: -50px;
          }
          .choose-title {
            margin-bottom: 50px;
            margin-top: 20px;
          }
          .choose-btn {
            padding: 8px 60px;
            background-color: #1154ff;
            color: #ffffff;
            border-radius: 5px;
            cursor: pointer;
          }
        }
      }
    }
    .step3 {
      padding: 50px 100px;
      .write {
        ::v-deep.avatar-uploader .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }
        ::v-deep.avatar-uploader .el-upload:hover {
          border-color: #409eff;
        }
        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 178px;
          height: 178px;
          line-height: 178px;
          text-align: center;
        }
        .avatar {
          width: 178px;
          height: 178px;
          display: block;
        }
        .speical-form {
          span {
            padding: 7px 40px;
            border: 1px solid #ededed;
            font-size: 12px;
            border-radius: 5px;
            margin-right: 10px;
            cursor: pointer;
            &.active {
              border: 1px solid #6d96ff;
              color: #6d96ff;
            }
          }
        }

        .next-step {
          text-align: center;
          margin-top: 30px;
          .btn {
            background-color: #1154ff;
            color: #ffffff;
            border: 1px solid #1154ff;
            padding: 10px 100px;
            border-radius: 5px;
            cursor: pointer;
          }
        }
      }
    }
    .step2 {
      padding: 50px 50px;

      .product {
        display: flex;
        justify-content: space-around;
        align-items: center;
        i{
          font-size: 40px;
          cursor: pointer;
        }
        .product-item {
          display: inline-block;
          width: 30%;
          border: 1px solid #dcdfe6;
          border-radius: 8px;
          text-align: center;
          height: 650px;
          margin: 0 5px;
          .versions {
            height: 120px;
            line-height: 120px;
            background-color: #428fff;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            color: #ffffff;
          }
          .price {
            div {
              color: #2f6aff;
              font-size: 35px;
              margin-top: 60px;
            }
            button {
              padding: 10px 120px;
              background-color: #1154ff;
              border: none;
              color: #ffffff;
              border-radius: 8px;
              margin: 40px 0;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
}
</style>
