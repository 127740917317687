<template>
  <div class="createIndex" v-if="load">
    <!-- <div class="topImg" :style="`background-image:url(${require('@/assets/images/createStep-topback.png')});`"></div> -->

    <div class="box" style="height: 200px; padding-top: 25px">
      <div class="title-box2">
        <div
          class="swiper-button-prev swiper-button-prev3"
          v-if="allProDuct.length > 6"
        ></div>
        <swiper
          ref="swiper"
          :options="{
            ...options2,
            slidesPerView: allProDuct.length > 6 ? 6 : allProDuct.length,
            resistanceRatio: allProDuct.length > 4 ? 0.6 : 0
          }"
        >
          <swiper-slide v-for="(item, i) in allProDuct" :key="i">
            <div
              :class="['title-card', proDuctIndex === i ? 'title-card-on' : '']"
              @click="changeProduct(i)"
              class="y-f"
            >
              <div
                style="font-weight: bold; font-size: 20px; text-align: center"
              >
                {{ item.productName }}
              </div>
              <div style="font-size: 14px; text-align: center">
                {{ item.introduction }}
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div
          class="swiper-button-next swiper-button-next3"
          v-if="allProDuct.length > 6"
        ></div>
      </div>
    </div>
    <div class="box">
      <div
        class="swipe-box"
        :style="`width:${(product.length > 4 ? 4 : product.length) * 330}px`"
      >
        <div
          class="swiper-button-prev swiper-button-prev2"
          v-if="swShow && product.length > 4"
        ></div>
        <swiper
          ref="swiper"
          v-if="swShow"
          :options="{
            ...options,
            slidesPerView: product.length > 4 ? 4 : product.length,
            resistanceRatio: product.length > 4 ? 0.6 : 0
          }"
        >
          <swiper-slide v-for="(item, index) in product" :key="index">
            <div class="card">
              <div class="title">{{ item.productName }}</div>
              <div class="price">
                <div class="left">￥</div>
                <div class="center">
                  {{ Number(getMinPay(item).pay).toFixed(2) }}
                </div>
                <div class="right">
                  /{{ getMinPay(item).text.replace('一', '') }}起
                </div>
              </div>
              <!-- <div class="price2">每天仅需{{ item.price2 }}元</div> -->
              <div class="descr">
                <div class="title2">{{ item.descrTitle }}</div>
                <div style="text-align: center; margin-bottom: 5px">
                  {{ item.introduction }}
                </div>
                <div>
                  <div
                    class="descr-text"
                    style="margin: 10px 0; font-size: 16px"
                  >
                    {{ item.productName }} 所有权益
                  </div>

                  <template
                    v-for="(textItem, i) in [
                      {
                        label: '默认用户数',
                        text:
                          item.payModelItems.length > 0
                            ? item.payModelItems[0].initializeUsers
                            : '',
                        show:
                          item.payModelItems.length > 0
                            ? item.payModelItems[0].isManageUsers
                            : ''
                      },
                      {
                        label: '默认门店数',
                        text:
                          item.payModelItems.length > 0
                            ? item.payModelItems[0].initializeShops
                            : '',
                        show:
                          item.payModelItems.length > 0
                            ? item.payModelItems[0].isManageShops
                            : ''
                      },
                      {
                        label: '默认站点数',
                        text:
                          item.payModelItems.length > 0
                            ? item.payModelItems[0].initializePoss
                            : '',
                        show:
                          item.payModelItems.length > 0
                            ? item.payModelItems[0].isManagePoss
                            : ''
                      }
                    ]"
                  >
                    <div class="descr-text" :key="i" v-if="textItem.show">
                      <i class="el-icon-check" style="margin-right: 2px"></i>
                      {{ textItem.label + '：' + (textItem.text || 1) }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="colorButton" @click="goBuy(index)">立即购买</div>
            </div>
          </swiper-slide>
        </swiper>
        <div
          class="swiper-button-next swiper-button-next2"
          v-if="swShow && product.length > 4"
        ></div>
      </div>
    </div>
    <div
      style="
        background-color: #ffffff;
        padding-bottom: 80px;
        margin-bottom: 80px;
      "
    >
      <div class="table-title">企业VIP会员特权服务</div>
      <div
        style="margin: 0 auto; margin-bottom: 12px"
        :style="`width: ${
          columns.map(x => x.width).reduce((pre, curr) => pre + curr) + 2
        }px`"
      >
        <span
          ><i
            class="el-icon-circle-check"
            style="color: #2eb79b; margin-right: 5px; font-size: 18px"
          ></i
          >支持功能</span
        >
        <span style="margin-left: 6px"
          ><i
            class="el-icon-remove-outline"
            style="color: #55a0ff; margin-right: 5px; font-size: 18px"
          ></i
          >支持部分功能</span
        >
        <span style="margin-left: 6px"
          ><i
            class="el-icon-circle-close"
            style="color: #8f8f8f; margin-right: 5px; font-size: 18px"
          ></i
          >不支持功能</span
        >
      </div>
      <div
        class="table-content"
        v-if="columns.length"
        :style="`width: ${
          columns.map(x => x.width).reduce((pre, curr) => pre + curr) + 2
        }px`"
      >
        <!--  :style="`height:${columns[0].width}px;`" -->
        <div class="heder" style="height: 80px">
          <div
            :style="`width:${columns[0].width}px;height: 100%`"
            class="header-card"
          >
            <div class="card-title">版本功能对比</div>
            <!-- <div class="card-descr">
                          <p>
                            <i class="el-icon-circle-check" style="color: #2eb79b; margin-right: 5px; font-size: 18px"></i>支持功能
                          </p>
                          <p>
                            <i class="el-icon-remove-outline" style="color: #55a0ff; margin-right: 5px; font-size: 18px"></i>支持部分功能
                          </p>
                          <p>
                            <i class="el-icon-circle-close" style="color: #8f8f8f; margin-right: 5px; font-size: 18px"></i>不支持功能
                          </p>
                        </div> -->
          </div>
          <template v-for="(item, index) in product">
            <div
              style="height: 100%"
              class="header-card"
              v-if="columns && columns[index + 1]"
              :key="index"
              :style="`width:${columns[index + 1].width}px;${
                index + 1 === onColumnIndex ? `background-color:#f3f4f5;` : ''
              }`"
              @mouseenter="handleMouseEnter(index + 1)"
              @mouseleave="handleMouseLeave()"
            >
              <div class="card-title">{{ item.productName }}</div>
              <!-- <div class="price" style="margin-bottom: 25px">
                              <div class="left">￥</div>
                              <div class="center">{{ getMinPay(item.productUsersShopsPayModels).pay }}</div>
                              <div class="right">/{{ getMinPay(item.productUsersShopsPayModels).text }}起</div>
                            </div>
                            <div class="colorButton" @click="goBuy">立即购买</div> -->
            </div>
          </template>
        </div>
        <TreeTable
          class="treeTable"
          v-if="columns.length"
          ref="treeTable"
          @cell-mouseenter="mouseenter"
          @cell-mouseleave="mouseleave"
          :data="data"
          :columns="columns"
          :selection-type="false"
          :show-header="false"
          :expand-type="false"
          :show-row-hover="false"
          :cell-class-name="cellClassName"
          :cell-style="cellStyle"
          :row-style="rowStyle"
          tree-type
        >
          <template
            :slot="`slot-${item.prop}`"
            scope="scope"
            v-for="(item, i) in columns"
          >
            <div :key="i">
              <i
                v-if="scope.row[item.prop] === 1"
                class="el-icon-circle-check"
                style="color: #2eb79b; margin-right: 5px; font-size: 18px"
              />
              <i
                v-else-if="scope.row[item.prop] === 2"
                class="el-icon-remove-outline"
                style="color: #55a0ff; margin-right: 5px; font-size: 18px"
              />
              <i
                v-else-if="scope.row[item.prop] === 3"
                class="el-icon-circle-close"
                style="color: #8f8f8f; margin-right: 5px; font-size: 18px"
              />
            </div>
          </template>
        </TreeTable>
      </div>
    </div>
  </div>
</template>
<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import TreeTable from 'vue-table-with-tree-grid'
import {
  productListTree,
  productDetail,
  getOnlineProductDetail
} from '@/api/tenant/product/saas.js' //接口api
import { deepCopy } from '@/utils'
import { fcount } from '@/utils'
import { compareList } from '@/api/tenant/product/compare'
export default {
  name: 'createIndex',
  components: { swiper, swiperSlide, TreeTable },
  data () {
    return {
      load: false,
      getMinPay: (data, index) => {
        //服务产品包年付费方式请求入参payModelItems
        let priceArr = data.payModelItems.map(x => {
          //产品包年包月模式  多长时间
          const item = this.yearsData.find(y => y.dictValue === x.payModelType)
          //门店价格
          let shopsPrice = 0
          let usersPrice = 0
          let possPrice = 0
          if (x.isManageShops) {
            //门店
            if (x.initializeShops) {
              //初始门店数和初始门店数单价有值才进来
              shopsPrice = fcount(
                [x.initializeShops, x.initializeShopsPrice],
                '*'
              )
            } else {
              //服务产品门店数价格回参  拿到购满数最小的
              const minElement = x.saasProductShopsPrices.reduce(
                (min, current) => {
                  if (current.shops < min.shops) {
                    return current
                  } else {
                    return min
                  }
                }
              )
              shopsPrice = minElement.shopsPrice
            }
          }
          if (x.isManageUsers) {
            //用户
            if (x.initializeUsers) {
              //初始用户数和初始用户数单价有值才进来
              usersPrice = fcount(
                [x.initializeUsers, x.initializeUsersPrice],
                '*'
              )
            } else {
              //服务产品用户数价格回参  拿到购满数最小的
              const minElement = x.saasProductUsersPrices.reduce(
                (min, current) => {
                  if (current.users < min.users) {
                    return current
                  } else {
                    return min
                  }
                }
              )
              usersPrice = minElement.usersPrice
            }
          }
          if (x.isManagePoss) {
            //站点
            if (x.initializePoss) {
              //初始站点数和初始站点数单价有值才进来
              possPrice = fcount([x.initializePoss, x.initializePossPrice], '*')
            } else {
              //服务产品站点数价格回参  拿到购满数最小的
              const minElement = x.saasProductPossPrices.reduce(
                (min, current) => {
                  if (current.poss < min.poss) {
                    return current
                  } else {
                    return min
                  }
                }
              )
              possPrice = minElement.possPrice
            }
          }
          //最优惠价格   默认产品价格+门店用户站点的价格
          let payModelPrice = fcount(
            [shopsPrice, usersPrice, possPrice, x.productPrice],
            '+'
          )
          // console.log('item', payModelPrice, data)
          // 获取最小价格
          return { payModelPrice, label: item.dictLabel }
        })
        // 取一个最低价显示
        const minProduct = priceArr.reduce((min, current) => {
          if (current.payModelPrice < min.payModelPrice) {
            return current
          } else {
            return min
          }
        })
        return {
          pay: minProduct?.payModelPrice || '0.00',
          text: minProduct?.label || '年'
        }
      },
      allProDuct: [],
      proDuctIndex: 0,
      cellClassName: (row, rowIndex, column, columnIndex) =>
        `columnIndex.${columnIndex}`,
      cellStyle: (row, rowIndex, column, columnIndex) =>
        columnIndex === this.onColumnIndex ? `background-color:#f3f4f5;` : '',
      rowStyle: (row, rowIndex) =>
        row._level > 1 ? 'background-color: #f7f7f7;' : '',
      onColumnIndex: -1,
      options: {
        slidesPerView: 4,
        spaceBetween: 30,
        pagination: {
          el: '.case-swiper-pagination'
          //区分不同Swiper的组件
        },
        navigation: {
          prevEl: '.swiper-button-prev2',
          nextEl: '.swiper-button-next2'
        },
        //左右箭头按钮(可自定义)
        on: {
          slideChangeTransitionEnd: function () {
            //获取当前下标，切换结束时，告诉我现在是第几个slide
          }
        }
      },
      options2: {
        slidesPerView: 4,
        spaceBetween: 30,
        pagination: {
          el: '.case-swiper-pagination2'
          //区分不同Swiper的组件
        },
        navigation: {
          prevEl: '.swiper-button-prev3',
          nextEl: '.swiper-button-next3'
        },
        //左右箭头按钮(可自定义)
        on: {
          slideChangeTransitionEnd: function () {
            //获取当前下标，切换结束时，告诉我现在是第几个slide
          }
        }
      },
      swShow: false,
      yearsData: [], //产品包年包月模式  多长时间
      product: [],
      columns: [],
      data: []
    }
  },
  async mounted () {
    // 获取价格
    try {
      const { rows } = await productListTree({
        pageNum: 1,
        pageSize: 15,
        status: '0', //上线状态
        productType: 1, //产品类型(1 系统产品 2 系统功能包 3 第三方功能包 4 服务包 5 硬件)
        delFlag: '0'
      })

      this.allProDuct = rows
    } catch (error) {}

    await this.changeProduct(0)
    this.load = true
  },
  methods: {
    async changeProduct (i) {
      this.loading = this.$loading({
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.3)'
      })

      this.proDuctIndex = i
      const { data } = await this.getDicts('product_years_package_mode')
      this.yearsData = data
      const res = await Promise.all(
        this.allProDuct[i].children.map(async x => {
          const { data } = await getOnlineProductDetail(x.productId)
          return data
        })
      )
      this.product = res.sort((a, b) => a.sort - b.sort)
      this.allProDuct[i].saasProductResp = this.product
      this.selectProDuct()
      try {
        this.loading.close()
      } catch (error) {}
      // console.log(this.product, res)
      this.swShow = false
      await this.$nextTick()
      this.swShow = true
    },
    async selectProDuct () {
      const versionItems = deepCopy(this.product)
      const res = await compareList()
      console.log('==', res)
      // 合并
      // let productL = this.product.length
      // for(let i = 0; i < productL; i++) {
      //
      // }
      // console.log('versionItems', deepCopy(this.product))
      // let compareTreeItemsArr = [].concat(
      //   ...versionItems.map(x => x.compareTreeItems)
      // )
      // let filterCompareTreeItemsArr =
      //   this.removeDuplicatesAndFlatten(compareTreeItemsArr)
      const end = versionItems.pop() // TODO合并并且去重的数组
      // console.log('compareTreeItemsArr', compareTreeItemsArr)
      // console.log('filterCompareTreeItemsArr', filterCompareTreeItemsArr)
      // console.log('end', end)
      const extractTree = data => {
        if (!data.length) return []
        const list = []
        const getObj = arr => {
          arr.forEach(row => {
            let obj = {}
            obj = JSON.parse(JSON.stringify(row))
            list.push(obj)
            if (row.children) {
              getObj(row.children)
            }
          })
          return list
        }
        return getObj(data)
      }
      const otherData = versionItems.map(x => {
        return {
          id: String(x.productId),
          menus: extractTree(deepCopy(x.compareTreeItems))
        }
      })
      const getOne = arr => {
        const obj = {}
        otherData.forEach((element, index) => {
          let haveNum = 0
          arr.forEach(x => {
            if (element.menus.find(y => y.id == x.id)) {
              // x.id
              haveNum = haveNum + 1
            }
          })
          obj[element.id] = !haveNum ? 3 : haveNum == arr.length ? 1 : 2
        })
        return obj
      }
      const extractTree2 = data => {
        return data.map((item, i) => {
          let obj = item.children
            ? {
                ...getOne(extractTree(deepCopy(item.children))),
                children: extractTree2(item.children)
              }
            : getOne([item])
          return { ...obj, name: item.label, [String(end.productId)]: 1 }
        })
      }
      this.columns = [
        { align: 'left', prop: 'name', width: 300 },
        ...this.product.map(x => ({
          align: 'center',
          prop: String(x.productId),
          width: 220,
          type: 'template',
          template: 'slot-' + x.productId
        }))
      ]

      this.data = extractTree2(end ? end.compareTreeItems : [])
      console.log('data', this.data)
      console.log('columns', this.columns)
    },
    removeDuplicatesAndFlatten (arr) {
      const result = []
      // 递归函数，用于处理子级元素
      function processChildren (children) {
        if (!children) return

        const uniqueChildren = []
        children.forEach(child => {
          // 检查当前子级是否已经存在于结果数组中
          const existingChild = uniqueChildren.find(c => c.id === child.id)
          if (!existingChild) {
            // 如果不存在，则将子级添加到新数组中
            uniqueChildren.push(child)
            // 递归处理子级的子级
            processChildren(child.children)
          }
        })

        // 将唯一的子级添加到结果数组中
        result.push(...uniqueChildren)
      }

      arr.forEach(item => {
        // 检查当前项是否已经存在于结果数组中
        const existingItem = result.find(i => i.id === item.id)
        if (!existingItem) {
          // 如果不存在，则将项添加到新数组中
          result.push(item)
          // 递归处理子级
          processChildren(item.children)
        }
      })

      return result
    },
    goBuy (sign) {
      this.$emit('select', this.allProDuct[this.proDuctIndex], sign)
      this.$emit('update:active', 2)
    },
    mouseenter (a) {
      const node = a.srcElement || a.target || a.toElement
      const onColumnIndex =
        node.className
          .split(' ')
          ?.find(x => x.indexOf('columnIndex') > -1)
          ?.split?.('.')?.[1] || -1
      this.onColumnIndex = onColumnIndex > 0 ? Number(onColumnIndex) : -1
    },
    mouseleave () {
      this.onColumnIndex = -1
    },
    handleMouseEnter (e) {
      this.onColumnIndex = e
    },
    handleMouseLeave () {
      this.onColumnIndex = -1
    }
  }
}
</script>
<style lang="scss" scoped>
.createIndex {
  .topImg {
    width: 100%;
    height: 14.58333vw;
    background-size: 100%;
  }

  .colorButton {
    width: 189px;
    height: 44px;
    background: linear-gradient(90deg, #338eff, #50d8ff);
    border-radius: 22px;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    line-height: 44px;
    cursor: pointer;
  }

  .price {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .left {
      font-size: 26px;
    }

    .center {
      font-size: 42px;
      line-height: 42px;
      color: #333333;
      font-weight: 800;
      margin-left: 2px;
    }

    .right {
      font-size: 16px;
      color: #666666;
    }
  }

  .box {
    height: 474px;
    padding: 40px 0;
    margin: 0 auto;
    position: relative;
    background-color: #eaeaea;

    .title-box2 {
      height: 200px;
      position: relative;
      max-width: 1380px;
      margin: 0 auto;
      display: flex;
      align-items: center;

      .title-card {
        &:hover {
          border: 1px solid #2e95ff;
        }

        cursor: pointer;
        height: 140px;
        border-radius: 8px;
        overflow: hidden;
        background-color: #ffffff;
        margin: 10px 8px;
        padding: 15px;
      }

      .title-card-on {
        background: #cae5fe;
        border: 1px solid #2e95ff;
        box-shadow: 0px 0px 23px 1px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
      }
    }

    .swipe-box {
      height: 100%;
      position: relative;
      max-width: 1320px;
      margin: 0 auto;
    }

    .swiper-container {
      width: calc(100% - 102px);
      height: 100%;

      .card {
        font-family: Microsoft YaHei;
        background-color: #ffffff;
        border-radius: 10px;
        overflow: hidden;
        height: 100%;
        text-align: center;

        &:hover {
          height: calc(100% - 2px);
          width: calc(100% - 2px);
          border: 1px solid #338eff;
        }

        .colorButton {
          position: absolute;
          bottom: 30px;
          left: 0;
          right: 0;
          margin: 0 auto;
        }

        .title {
          height: 84px;
          line-height: 84px;
          font-size: 24px;
          font-weight: bold;
          color: #333333;
        }

        .price2 {
          font-size: 14px;
          padding: 5px 0;
          color: #666666;
        }

        .descr {
          margin: 0 auto;
          text-align: left;
          margin-top: 20px;
          display: inline-grid;

          .title2 {
            font-size: 16px;
            line-height: 25px;
            color: #333333;
          }

          .descr-text {
            font-size: 14px;
            color: #666666;
          }
        }
      }
    }

    .swiper-slide .card {
      width: 100%;
    }
  }

  .table-title {
    font-size: 32px;
    text-align: center;
    padding: 50px 0;
  }

  .table-content {
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.1);
    margin: 0 auto;

    ::v-deep .zk-icon-minus-square-o:before {
      content: '\E633' !important;
      transform: rotate(90deg) !important;
      display: -webkit-inline-box;
    }

    ::v-deep .zk-icon-plus-square-o:before {
      content: '\E633' !important;
    }

    .heder {
      position: -webkit-sticky;
      position: sticky;
      top: 0px;
      width: 100%;
      display: flex;
      z-index: 99;
      background-color: #ffffff;
      border: 1px solid #e9eaec;
      border-bottom: none;
      border-right: none;

      .header-card {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #e9eaec;

        .card-title {
          font-size: 28px;
          color: #333333;
          padding: 30px;
        }

        .card-descr {
          font-size: 18px;
          text-align: left;
        }
      }
    }
  }
}
</style>
