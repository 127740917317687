<!-- 选择产品 -->
<template>
  <!-- 续费产品 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import TablePage from '@/components/tablePage/index.vue'
import { getTenantProductToRenew } from '@/api/tenant/base/info' //所有商户

export default {
  name: 'TenantList',
  components: { TablePage },
  data () {
    return {
      options: {
        hidePagination: true,
        listNo: true,
        loading: true,
        listName: 'data',
        mutiSelect: true,
        check: [],
        buttons: [
          {
            click: 'add',
            label: '产品续费',
            type: 'primary'
          }
        ],
        defaultBody: {
          tenantId: this.$route.query.tenantId
        },
        getListApi: getTenantProductToRenew,
        title: '商户产品',
        rowKey: 'productBookDetailId',
        columns: [
          { prop: 'belongProductName', label: '所属产品', minWidth: '200' },
          { prop: 'productNo', label: '产品编号', minWidth: '200' },
          { prop: 'productName', label: '产品名称', width: '200' },
          { prop: 'productCalcModeName', label: '计费模式', minWidth: '200' },
          { prop: 'validBegTime', label: '有效期开始', minWidth: '200' },
          { prop: 'validEndTime', label: '有效期结束', minWidth: '160' }
        ],
        list: []
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      switch (type) {
        case 'add':
          if (this.options.check.length == 0)
            return this.$message.error('至少勾选一个产品')

          this.$router.push({
            path: '/Creation/renew',
            query: {
              tenantId: this.$route.query.tenantId,
              ids: this.options.check
                .map(item => item.productBookDetailId)
                .join(',')
            }
          })
          break
      }
    }
  }
}
</script>
<style scoped lang="scss">
.wrap {
  display: flex;
  flex-direction: column;
  padding-top: 35px;
  box-sizing: border-box;
  background-color: #eaeaea;
  height: calc(100vh - 130px);
  ::v-deep {
    #table-page {
      padding: 0;
      width: 1200px;
      margin: auto;
      background-color: transparent;
      height: 100%;
      display: flex;
      flex-direction: column;

      .el-card {
        margin-top: 66px;
        height: calc(100% - 66px);
        .cardContent {
          > div {
            &:nth-of-type(1) {
              .el-form {
                padding: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
