<template>
  <div class="console">
    <div class="top">
      <span class="account">{{ $store.getters.name }}</span>
      <button style="cursor: pointer" @click="tenant('CT')">创建商户</button>
      <button style="cursor: pointer" @click="tenant('add')">加入商户</button>
    </div>
    <cardTitleCom cardTitle="商户">
      <template slot="cardContent">
        <div class="tenant">
          <div
            class="tenant-item"
            v-for="(item, index) in myTenantList"
            @click="getHome(item.productId, item.tenantId)"
            :key="index"
          >
            <div class="title">
              <div class="left">
                <svg-icon icon-class="peoples" />
                <span class="name">{{ item.simpleName }}</span>
                <span>({{ item.tenantNo }})</span>
              </div>
              <div class="right">
                <img
                  style="width: 50px; height: 50px"
                  :src="item.logoUrl || Imglogo"
                  alt="加载失败"
                />
              </div>
            </div>
            <div class="detail">
              <div class="item">
                <span>产品版本：</span>
                <span>{{ item.productVersion }}</span>
              </div>
              <div class="item">
                <span>产品状态：</span>
                <span>{{ item.status === "0" ? "启用" : "停用" }}</span>
              </div>
              <div class="item">
                <span>产品名称：</span>
                <span>{{ item.productName }}</span>
              </div>
              <div class="item">
                <span>到期时间：</span>
                <span>{{ item.productExpiresTime }}</span>
                <span
                  v-if="showExpiresTime(item.productExpiresTime)"
                  class="isExpire"
                  @click.stop="fn2"
                  >立即续费</span
                >
              </div>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <div class="bottom">
      <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="商户" name="allTenant"> </el-tab-pane>
        <!-- <el-tab-pane label="加入的商户" name="second">
            <div class="tenant">
              <div
                class="tenant-item"
                @click="fn1"
                v-for="(item, index) in 10"
                :key="index"
              >
                <div class="title">
                  <div class="left">
                    <svg-icon icon-class="peoples" />
                    <span class="name">隼云科技</span>
                    <span>(120587)</span>
                  </div>
                  <div class="right">
                    <img
                      style="width:50px;height:50px"
                      src="../../../assets/images/mty.png"
                      alt="加载失败"
                    />
                  </div>
                </div>
                <div class="detail">
                  <div class="item">
                    <span>产品版本：</span>
                    <span>1.0.0</span>
                  </div>
                  <div class="item">
                    <span>组织状态：</span>
                    <span>1.0.0</span>
                  </div>
                  <div class="item">
                    <span>产品名称：</span>
                    <span>1.0.0</span>
                  </div>
                  <div class="item">
                    <span>到期时间：</span>
                    <span>1.0.0</span>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { TenantListAPI } from "../../../api/system/tenant";
import Img from "@/assets/imgs/loginimgs/sunyun-logo9.png";
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
export default {
  name: "CONSOLE",
  components: { cardTitleCom },
  data() {
    return {
      activeName: "allTenant",
      //我的商户列表
      myTenantList: [],
      //默认头像
      Imglogo: Img,
    };
  },
  async created() {
    await this.getMyTenantList();
  },
  methods: {
    //判断到期时间
    showExpiresTime(Time) {
      const pastTime = new Date(Time).getTime();
      const currentTime = new Date().getTime();
      return currentTime >= pastTime ? true : false;
    },
    //获取我的商户列表 、、、
    async getMyTenantList() {
      const res = await TenantListAPI();
      this.myTenantList = res.data;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    //进入商户后台事件
    async getHome(productId, tenantId) {
      this.$store.commit("SET_PRODUCTID", productId);
      this.$store.commit("SET_TENANTID", tenantId);
      this.$router.push("/index");
    },
    //立即续费的事件
    fn2() {
      console.log("没冒泡");
    },
    tenant(type) {
      if (type === "CT") {
        this.$router.push("/Creation/createStep"); //创建商户
      } else {
        this.$router.push("/Creation/JoinTenant"); //加入商户
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.console {
  height: 100vh;

  .top {
    height: 100px;
    background-color: #ffffff;
    padding-left: 20px;
    line-height: 100px;
    margin-bottom: 20px;

    .account {
      border-right: 2px solid #e9edef;
      padding: 8px 15px;
    }

    button {
      border: none;
      border-radius: 5%;
      background-color: #165dff;
      color: #ffffff;
      padding: 8px 15px;
      margin-left: 10px;
    }
  }

  .bottom {
    ::v-deep.el-tabs__content {
      padding: 15px 0 15px 35px;

      .tenant {
        display: flex;
        flex-wrap: wrap;

        .tenant-item {
          border-radius: 5px;
          width: 31%;
          padding: 0 10px;
          border: 1px solid #ccc;
          cursor: pointer;
          font-size: 16px;
          margin: 24px 24px 0 0;
          background-color: transparent;

          .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #ccc;

            .left {
              .name {
                margin: 0 10px;
              }
            }

            img {
              vertical-align: middle;
            }
          }

          .detail {
            font-size: 14px;

            .item {
              padding: 10px;

              span.isExpire {
                margin-left: 5px;
                color: #f53f3f;
                width: 58px;
                height: 20px;
                background: #f7f8fa;
                text-align: center;
                padding: 0 9px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
