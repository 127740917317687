var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wap",
      class: ["creation", _vm.$route.path.split("/").pop()],
    },
    [
      _vm.$route.meta.noLayout
        ? _c("router-view")
        : [
            _c("div", { staticClass: "nav" }, [
              _c("div", { staticClass: "title" }, [
                _c("div", { staticClass: "left" }, [
                  _c(
                    "div",
                    { staticClass: "logo", on: { click: _vm.toCreation } },
                    [
                      _c("img", {
                        staticStyle: { width: "100px", height: "50px" },
                        attrs: { src: _vm.logo, alt: "加载失败" },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "marL10" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.toCreation },
                        },
                        [_vm._v("我的商户")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.toOrderList()
                          },
                        },
                      },
                      [_vm._v("订单管理")]
                    ),
                    _c(
                      "el-dropdown",
                      {
                        staticClass:
                          "avatar-container right-menu-item hover-effect",
                        attrs: { trigger: "click" },
                      },
                      [
                        _c("div", { staticClass: "avatar-wrapper" }, [
                          _c("img", {
                            staticClass: "user-avatar",
                            attrs: { src: _vm.avatar },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-left": "5px",
                                color: "#1890ff",
                              },
                            },
                            [_vm._v(_vm._s(_vm.name))]
                          ),
                        ]),
                        _c("el-dropdown-menu", {
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (dropdown) {
                                return [
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.logout($event)
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("退出登录")])]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.toUser($event)
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("个人中心")])]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "main" }, [_c("router-view")], 1),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }