<template>
  <!-- 加入商户 -->
  <div class="join-tenant">
    <div class="x-f warp">
      <div class="left">
        <div class="logo">
          <img
            src="@/assets/images/sunyun-logo2.svg"
            alt="加载失败"
            style="width: 30%; height: 30%"
          />
        </div>
        <div class="introduce">
          您是烘焙达人，我们是管理专家 给您提供快速、高效、创新的数字化解决方案
          覆盖烘焙行业全场景的高效管理系统
        </div>
        <div class="joinMerchant">
          <img
            src="@/assets/images/joinMerchant.svg"
            alt="加载失败"
            style="width: 80%; height: 80%"
          />
        </div>
      </div>
      <div class="right x-fc">
        <div>
          <div class="marB20 x-f" @click="$router.go(-1)">
            <i class="el-icon-arrow-left marR5 cursorP arrowIcon"></i>
            <div class="operatePush arrowBack">返回</div>
          </div>

          <el-card class="joinBox x-c" v-show="isSuccess">
            <div class="title">输入要加入的商户号</div>
            <div class="x-c searchBox">
              <el-form :model="form" :rules="rules" ref="form">
                <el-form-item prop="tenantNo" class="x-c">
                  <el-input
                    class="merchantInput marR10"
                    v-model="form.tenantNo"
                    placeholder="请输入7位商户号进行查找"
                  />
                  <el-button
                    icon="el-icon-search"
                    type="primary"
                    @click="hanSearch"
                    >查找</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
            <div
              class="merchantInfo"
              :style="{
                backgroundColor:
                  merchantInfoNo || merchantInfoName || errorMsg
                    ? ' #f8f8f8'
                    : '#ffffff'
              }"
            >
              <div v-show="merchantInfoNo">
                {{ '商户编号：' + merchantInfoNo }}
              </div>
              <div v-show="merchantInfoName">
                {{ '商户名称：' + merchantInfoName }}
              </div>
              <div v-show="errorMsg">{{ errorMsg }}</div>
            </div>
            <el-button
              type="primary"
              class="marB10"
              @click="handJoin"
              :loading="loadingJoin"
              :disabled="!this.merchantInfoName"
              >确认申请加入</el-button
            >
            <div class="prompt">
              如果您不知道所要加入的商户号，可以联系公司相关负责人
            </div>
          </el-card>
          <el-card class="joinBox x-c" v-show="!isSuccess">
            <div>
              <el-result icon="success" />
              <div>
                <div class="successTopTitle">
                  确认申请加入完成，等待商户审核...
                </div>
                <div class="successCenterTitle">
                  {{ countdown }}秒后自动跳到商户列表
                </div>
                <div class="successBootomTitle">
                  <span
                    class="lookList cursorP"
                    @click="$router.push('/creation/console')"
                    >查看商户列表</span
                  >
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { JoinTenantAPI, findByTenantNo } from '@/api/system/tenant'
export default {
  name: 'JoinTenant',
  data () {
    return {
      errorMsg: undefined,
      //倒计时
      countdown: 3,
      //加入成功
      isSuccess: true,
      //加入
      loadingJoin: false,
      //商户的信息编号
      merchantInfoNo: undefined,
      //商户的信息名称
      merchantInfoName: undefined,
      //加入商户表单数据
      form: {
        tenantNo: undefined //商户id
      },
      //校验规则
      rules: {
        tenantNo: [
          {
            required: true,
            message: '请输入商户号',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^[0-9]{7}$/,
            message: '请输入7位数值类型的商号户',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  methods: {
    //加入
    handJoin () {
      this.loadingJoin = true
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$confirm(
            `您确定申请加入${this.form.tenantNo} (${this.merchantInfoName})商户?`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }
          )
            .then(async () => {
              await JoinTenantAPI(this.form.tenantNo)
              this.isSuccess = false
              // this.$message.success('加入商户成功!请等待系统审核')
              this.countdown = 3
              const countdownTimer = setInterval(() => {
                this.countdown--
                // Check if the countdown has reached 0
                if (this.countdown < 0) {
                  // Display a message or perform any desired action when countdown finishes
                  this.$router.push('/creation/console')
                  clearInterval(countdownTimer) // Stop the countdown timer
                }
              }, 1000)
            })
            .catch(() => {})
        }
      })
      this.loadingJoin = false
    },
    //查找
    async hanSearch () {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          try {
            const res = await findByTenantNo(this.form.tenantNo)
            this.merchantInfoName = res.data.tenantName
            this.merchantInfoNo = res.data.tenantNo
            this.$message.success('查询成功')
          } catch (error) {
            this.merchantInfoName = undefined
            this.merchantInfoNo = undefined
            this.errorMsg = '没有匹配的商户信息!'
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.join-tenant {
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
  .warp {
    width: 100%;
    height: 100%;
    .left {
      width: 424px;
      height: 100%;
      background-color: #ffffff;
      //logo
      .logo {
        width: 100%;
      }
      //介绍
      .introduce {
        margin-top: 30%;
        padding: 0 15%;
        color: #19233a;
        font-weight: 400;
      }
      //加入商户
      .joinMerchant {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15%;
        width: 100%;
      }
    }
    .right {
      flex: 1;
      height: 100%;
      .arrowIcon {
        color: #388be2;
      }
      .arrowIcon:hover {
        color: #5ba9f9;
        opacity: 0.8; /* 悬停时透明度变为80% */
      }
      .arrowBack {
        border-bottom: 1px solid #388be2;
      }
      .joinBox {
        height: 400px;
        width: 600px;
        ::v-deep .el-result__icon svg {
          width: 50px;
          height: 50px;
          margin-bottom: 10px;
        }
        .successTopTitle {
          text-align: center;
          font-size: 14px;
          color: #999999;
        }
        .successCenterTitle {
          text-align: center;
          font-size: 14px;
          color: #999999;
        }
        .successBootomTitle {
          text-align: center;
          font-size: 14px;
          margin-top: 5px;
          color: #388be2;
          .lookList {
            border-bottom: 1px solid #388be2;
          }
        }
        ::v-deep .el-button--medium {
          padding: 14px 20px;
        }
        ::v-deep .el-card__body {
          padding: 0;
          width: 450px;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
        .title {
          // margin-top: 50px;
          color: #333333;
          font-weight: bold;
          font-size: 20px;
          margin-bottom: 30px;
        }
        //搜索框
        .searchBox {
          // margin-bottom: 150px;
          ::v-deep .el-input--medium .el-input__inner {
            height: 44px;
            line-height: 44px;
          }
          //输入框
          .merchantInput {
            width: 350px;
          }
        }
        //商户信息
        .merchantInfo {
          height: 86px;
          margin: 10px 0 10px 0;
          padding: 5px 10px;
          overflow: hidden; //超出的文本隐藏

          text-overflow: ellipsis; //溢出用省略号显示

          display: -webkit-box;

          -webkit-line-clamp: 4; // 超出多少行

          -webkit-box-orient: vertical;
        }
        //提示
        .prompt {
          text-align: center;
          font-size: 14px;
          color: #999999;
        }
      }
    }
  }
  ::v-deep .el-form-item {
    margin: 0;
  }
}
::v-deep .el-result {
  padding: 0;
}
</style>
