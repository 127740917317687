var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-service" }, [
    _vm._m(0),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.cur === 0,
              expression: "cur === 0",
            },
          ],
          staticClass: "order",
        },
        [
          _c("div", [_vm._v("产品版本")]),
          _c("div", { staticClass: "versions" }, [
            _vm._v(_vm._s(_vm.productDetail.versionName)),
          ]),
          _c("div", [_vm._v("购买时长")]),
          _c("div", { staticClass: "duration" }, [
            _vm._v(_vm._s(_vm.productDetail.serviceTime)),
          ]),
          _c("div", [_vm._v("用户人数")]),
          _c(
            "div",
            { staticClass: "amount" },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 10,
                  size: "mini",
                },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.productDetail.maxUsers,
                  callback: function ($$v) {
                    _vm.$set(_vm.productDetail, "maxUsers", $$v)
                  },
                  expression: "productDetail.maxUsers",
                },
              }),
              _vm._v("人 "),
              _c("span"),
            ],
            1
          ),
          _c("div"),
          _c("div", { staticClass: "hr" }),
          _c("div", { staticClass: "total" }, [
            _c("span", [_vm._v("产品价格")]),
            _c("span", { staticClass: "symbol" }, [_vm._v("¥")]),
            _c("span", { staticClass: "price" }, [
              _vm._v(_vm._s(_vm.productDetail.productPrice) + ".00"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "div",
                { staticClass: "btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.submit(
                            "order",
                            _vm.productDetail.versionId,
                            _vm.productDetail.maxUsers
                          )
                        },
                      },
                    },
                    [_vm._v("提交订单")]
                  ),
                ],
                1
              ),
              _c(
                "el-checkbox",
                {
                  staticClass: "tcp",
                  staticStyle: { fontSize: "10px" },
                  model: {
                    value: _vm.checked,
                    callback: function ($$v) {
                      _vm.checked = $$v
                    },
                    expression: "checked",
                  },
                },
                [_vm._v("我已阅读同意《隼云科技服务协议》")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.cur === 1,
              expression: "cur === 1",
            },
          ],
          staticClass: "pay",
        },
        [
          _c("div", { staticClass: "detail" }, [
            _vm._m(1),
            _c("div", { staticClass: "middle" }, [
              _c("div", { staticClass: "hint" }, [
                _vm._v("订单提交成功！ 请尽快完成支付"),
              ]),
              _c("div", [
                _vm._v("请在"),
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v(_vm._s(_vm.orderDetail.orderTimeOut) + "分钟"),
                ]),
                _vm._v("内完成支付，超时后将自动取消订单"),
              ]),
              _c("div", [
                _vm._v("订单编号"),
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v(_vm._s(_vm.orderDetail.outTradeNo)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "right" }, [
              _c("span", { staticClass: "symbol" }, [_vm._v("¥")]),
              _c("span", [_vm._v(_vm._s(_vm.orderDetail.orderAmount))]),
            ]),
          ]),
          _c("div", { staticClass: "hr" }),
          _c("div", { staticClass: "info" }, [
            _c("div", [_vm._v("商品信息")]),
            _c(
              "table",
              {
                staticStyle: {
                  width: "600px",
                  margin: "25px 0",
                  textAlign: "center",
                  fontSize: "14px",
                },
                attrs: { cellspacing: "0" },
              },
              [
                _vm._m(2),
                _c(
                  "tr",
                  {
                    staticStyle: {
                      backgroundColor: "#ffffff",
                      fontSize: "12px",
                    },
                  },
                  [
                    _c("td", [_vm._v(_vm._s(_vm.orderDetail.productName))]),
                    _c("td", [_vm._v(_vm._s(_vm.orderDetail.versionName))]),
                    _c("td", [_vm._v(_vm._s(_vm.orderDetail.maxUsers))]),
                    _c("td", [_vm._v(_vm._s(_vm.orderDetail.serviceTime))]),
                  ]
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.pay },
                },
                [_vm._v("立即支付")]
              ),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "name" }, [
        _c("span", [_vm._v("隼云")]),
        _c("span", [_vm._v("标准版产品服务")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c(
        "span",
        {
          staticStyle: {
            backgroundColor: "#00b42a",
            padding: "5px",
            borderRadius: "50%",
          },
        },
        [
          _c("i", {
            staticClass: "el-icon-check",
            staticStyle: { color: "#fff" },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticStyle: { backgroundColor: "#f8f8f9" } }, [
      _c("td", [_vm._v("产品名称")]),
      _c("td", [_vm._v("产品版本")]),
      _c("td", [_vm._v("用户数")]),
      _c("td", [_vm._v("购买时长")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }