<template>
  <div class="console" v-loading="loading">
    <div class="top">
      <el-button type="primary" @click="tenant('CT')">创建商户</el-button>
      <el-button type="primary" @click="tenant('add')">加入商户</el-button>
    </div>
    <cardTitleCom cardTitle="商户">
      <template slot="cardContent">
        <div class="tenant">
          <div class="tenant-item" v-for="(item, index) in myTenantList" @click="getHome(item)" :key="index">
            <!-- 0 审核中 2 审核未通过 -->
            <div class="tenant-ining x-c" v-if="item.status != 0">
              <!--              <div class="text">未审核</div>-->
              <!--                <i class="el-icon-circle-close fontS25" style="color: #ff3e54"></i>-->
            </div>
            <div class="head" :style="
                item.generationTyp !== 0
                  ? 'background: linear-gradient(90deg, #409EFF, #45E2FF);'
                  : ''
              ">
              <div class="logo-view">
                <el-image style="width: 69px; height: 25px" :src="require('@/assets/images/logo-c.png')" />
                <div style="font-size: 14px; color: #ffffff">
                  <div v-if="item.status == 0">
                    <span class="isExpire" @click.stop="fn2">{{
                      item.generationTyp === 0 ? '创建' : '已加入'
                    }}</span>
                  </div>
                  <div v-else>
                    <span class="isExpire" v-for="state in statusList" :key="state.value">
                      <span v-if="state.value == item.status">{{
                        state.label
                      }}</span>
                    </span>
                  </div>
                  <!-- <span v-if="showExpiresTime(item.productExpiresTime)" class="isExpire" @click.stop="fn2">续费</span> -->
                </div>
              </div>
              <div class="descr">
                <el-image style="
                    width: 46px;
                    height: 46px;
                    border-radius: 100px;
                    overflow: hidden;
                  " :src="item.logoUrl">
                  <div slot="error" class="image-slot">
                    <!-- <i class="el-icon-picture-outline"></i>  -->
                  </div>
                </el-image>
                <div style="font-size: 14px; color: #ffffff">
                  <div style="line-height: 24px">{{ item.tenantName }}</div>
                  <div style="line-height: 26px; font-size: 12px">
                    商户号：{{ item.tenantNo }}
                  </div>
                </div>
              </div>
            </div>
            <div class="detail">
              <div>
                <span class="title">产品版本：</span>
                <span>{{ item.productVersion }}</span>
              </div>
              <div>
                <span class="title">商户状态：</span>
                <span v-for="state in statusList" :key="state.value">
                  <span v-if="state.value == item.status">{{
                    state.label
                  }}</span>
                </span>
              </div>
              <div>
                <span class="title">产品名称：</span>
                <span>{{ item.productName }}</span>
              </div>
              <div class="x-bc">
                <span>
                  <span class="title">到期时间：</span>
                  <span>{{ item.validEndTime }}</span>
                </span>
                <el-button size="mini" type="primary" class="marl" @click.stop="goToRenew(item.tenantId)">续费</el-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import { switchTenant, TenantListAPI } from '../../../api/system/tenant'
import Img from '@/assets/imgs/loginimgs/sunyun-logo9.png'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import moment from 'moment'
export default {
  name: 'CONSOLE',
  components: { cardTitleCom },
  data() {
    return {
      loading: false,
      formatDate: date => {
        return date ? moment(date).format('YYYY-MM-DD hh:mm:ss') : ''
      },
      activeName: 'allTenant',
      //我的商户列表
      myTenantList: [],
      //默认头像
      Imglogo: Img,
      statusList: [
        { label: '未审核', value: -1 },
        { label: '正常', value: 0 },
        { label: '停用', value: 1 },
        { label: '过期', value: 2 },
        { label: '删除', value: 3 }
      ]
    }
  },
  async created() {
    await this.getMyTenantList()
  },
  methods: {
    //判断到期时间
    showExpiresTime(Time) {
      const pastTime = new Date(Time).getTime()
      const currentTime = new Date().getTime()
      return currentTime >= pastTime ? true : false
    },
    //获取我的商户列表
    async getMyTenantList() {
      this.loading = true
      const res = await TenantListAPI()
      this.myTenantList = res.data
      this.loading = false
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
    //进入商户后台事件
    async getHome(item) {
      if (+item.status === 0) {
        try {
          console.log('切换1');
          await switchTenant({
            tenantId: item.tenantId
          })
          console.log('切换2');
          this.$tab.closeAllPage()
          this.$message.success('切换商户成功')
          console.log('切换2.1');
          this.$store.commit('SET_PRODUCTID', item.productId)
          console.log('切换2.2');
          this.$store.commit('SET_TENANTID', item.tenantId)
          console.log('切换2.3');
          this.$router.push('/index')
          console.log('切换3');
        } catch (error) { }
      }
    },
    //立即续费的事件
    fn2() {
      console.log('没冒泡')
    },
    tenant(type) {
      if (type === 'CT') {
        this.$router.push('/Creation/createStep') //创建商户
      } else {
        this.$router.push('/Creation/JoinTenant') //加入商户
      }
    },
    goToRenew(tenantId) {
      this.$router.push(`/Creation/handleProduct?tenantId=${tenantId}`) //创建商户
    }
  }
}
</script>

<style lang="scss" scoped>
.console {
  .top {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .tenant {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 10px;
    margin-bottom: 60px;

    .tenant-item {
      position: relative;
      &:hover {
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
      }

      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      width: calc(25% - 20px);
      margin: 10px;
      border-radius: 6px;
      overflow: hidden;
      .tenant-ining {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 99;
        background-color: rgba(0, 0, 0, 0.45);
        .text {
          height: 16px;
          text-align: center;
          color: #ffffff;
        }
      }

      .head {
        height: 180px;
        padding: 8px;
        background: linear-gradient(90deg, #378fea, #5db8f8);
        border-radius: 8px 8px 0px 0px;

        .logo-view {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .descr {
          height: calc(100% - 50px);
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          margin: 10px 0;
          text-align: center;
        }
      }

      .detail {
        padding: 43px 10px;
        font-size: 14px;
        line-height: 28px;
        .title {
          min-width: 70px;
        }
      }
    }
  }
}
</style>
