<template>
  <div class="wrap">
    <TablePage v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import { getMyOrderList } from '@/api/shop/mobileQueryApp/index.js'
import TablePage from '@/components/tablePage'
import { orderDetail } from '@/api/creation'

export default {
  name: 'OrderList',
  components: { TablePage },
  data () {
    return {
      options: {
        listNo: true,
        radioSelect: true,
        loading: true,
        check: [],
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '订单列表',
        rowKey: 'orderNo',
        height: 550,
        defaultBody: {
          userId: this.$store.state.user.userinfo.userId
        },
        delHeight: 37,
        getListApi: getMyOrderList,
        buttons: [
          {
            click: 'pay',
            label: '支付',
            type: 'primary'
          },
          {
            right: true,
            click: 'back',
            label: '返回'
          }
        ],
        columns: [
          {
            prop: 'orderNo',
            label: '订单号',
            minWidth: 220,
            align: 'center'
          },
          {
            prop: 'createTime',
            label: '订单时间',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'payStatusName',
            label: '支付状态',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'linkMan',
            label: '联系人',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'linkManTel',
            label: '联系电话',
            minWidth: 110,
            align: 'center'
          },
          {
            prop: 'accountProductOrderDetailResp',
            label: '产品',
            minWidth: 160,
            align: 'left',
            activeText (row) {
              return row.productName
            }
          },
          {
            prop: 'orderPayMoney',
            label: '金额（元）', // 实付金额
            minWidth: 160,
            align: 'center'
          },
          {
            prop: 'orderPayModeName',
            label: '支付方式',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'orderPayTime', // 支付时间
            label: '购买日期',
            minWidth: 155,
            align: 'center'
          }
        ],
        list: []
      }
    }
  },

  async mounted () {},
  methods: {
    handleEvent (type, row) {
      switch (type) {
        case 'back':
          try {
            this.$router.go(-1)
          } catch (error) {}
          break
        case 'pay':
          const orderId = this.options.radioObject.orderId
          if (
            this.options.radioObject.orderStatus === 2 ||
            this.options.radioObject.orderStatus === 3
          ) {
            this.$message.error('该订单已支付，请勿重复支付！')
            return
          }

          // 获取详情
          orderDetail({
            orderId
          }).then(res => {
            this.$router.push({
              path: '/Creation/createStep',
              query: {
                orderNo: res.data.orderNo,
                money: res.data.orderMoney
              }
            })
          })
          break
      }
    }
  }
}
</script>
<style scoped lang="scss">
.wrap {
  display: flex;
  flex-direction: column;
  padding-top: 35px;
  box-sizing: border-box;
  //  height: 100vh;
  background-color: #eaeaea;

  ::v-deep {
    #table-page {
      padding: 0;
      width: 1200px;
      margin: auto;
      background-color: transparent;
      height: 100%;
      display: flex;
      flex-direction: column;

      .el-card {
        margin: 66px auto 0 !important;
        height: 100%;
        .cardContent {
          > div {
            &:nth-of-type(1) {
              .el-form {
                padding: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
