<template>
  <div class="container">
    <div id="buyStepComBox" class="buy-step-com" v-loading="loading">
      <div class="topBox y-start" :style="{ width: `${topBoxHeight}px` }">
        <div class="top-btns x-c-end">
          <el-button size="mini" v-if="[1, 2, 3].includes(step)" @click="updateStep()">上一步</el-button>
          <el-button size="mini" v-if="[1, 2].includes(step)" @click="updateStep(true)">下一步</el-button>
          <el-button size="mini" v-if="step === 4" @click="updateStep(true)">完成</el-button>
        </div>

        <cardTitleCom cardTitle="当前进度" style="width: 100%; margin-bottom: 0px">
          <template slot="cardContent">
            <div class="padd10">
              <el-steps :active="step" size="mini" align-center class="step">
                <el-step v-for="title in [
                    '选择产品规格',
                    '确认订单',
                    '去支付',
                    '支付成功',
                  ]" :key="title" :title="title"></el-step>
              </el-steps>
            </div>
          </template>
        </cardTitleCom>
      </div>

      <div class="bottomBox">
        <cardTitleCom cardTitle="产品规格" v-show="step === 1" style="margin-bottom: 0">
          <template slot="cardContent">
            <div style="margin: 10px">
              <myForm label-position="left-label" v-if="showForm" v-model="productForm" ref="myForm"
                :options="productFormOption" label-width="120px" />
            </div>
          </template>
        </cardTitleCom>

        <div v-show="step === 2" v-if="orderDetail">
          <cardTitleCom cardTitle="产品规格">
            <template slot="cardContent">
              <el-descriptions :column="1" labelClassName="my-descriptions-label">
                <el-descriptions-item label="服务名称">
                  {{
                    `${product[0].parentProductName}（${productData[active].productName}）`
                  }}
                </el-descriptions-item>
                <el-descriptions-item label="规格" v-if="
                    (productForm.isManageUsers && productForm.userNumber > 0) ||
                    (productForm.isManageShops && productForm.shopNumber > 0) ||
                    (productForm.isManagePoss && productForm.possNumber > 0)
                  ">
                  <span v-if="
                      productForm.isManageUsers && productForm.userNumber > 0
                    ">
                    {{ `${productForm.userNumber}个员工账户` }}
                  </span>
                  <span v-if="
                      productForm.isManageShops && productForm.shopNumber > 0
                    ">
                    <span v-if="
                        productForm.isManageUsers && productForm.userNumber > 0
                      ">+</span>
                    {{ `${productForm.shopNumber}个门店数` }}
                  </span>
                  <span v-if="
                      productForm.isManagePoss && productForm.possNumber > 0
                    ">
                    <span v-if="
                        (productForm.isManageUsers &&
                          productForm.userNumber > 0) ||
                        (productForm.isManageShops &&
                          productForm.shopNumber > 0)
                      ">+</span>
                    {{ `${productForm.possNumber}个站点数` }}
                  </span>
                </el-descriptions-item>
                <el-descriptions-item label="有效期">{{
                  orderDetail.unit
                }}</el-descriptions-item>
                <el-descriptions-item label="原价">{{ Number(orderDetail.oldOrderMoney).toFixed(2) }}元
                </el-descriptions-item>
                <el-descriptions-item label="优惠券">无可用优惠券</el-descriptions-item>
                <el-descriptions-item label="合计">{{ Number(orderDetail.orderMoney).toFixed(2) }}元
                </el-descriptions-item>
              </el-descriptions>
            </template>
          </cardTitleCom>
          <cardTitleCom cardTitle="用户须知">
            <template slot="cardContent">
              <div class="padd10">
                <el-checkbox v-model="checked"></el-checkbox>
                <span style="font-size: 12px; margin-left: 5px; line-height: 23px">
                  我已阅读并同意
                  <el-link type="primary" style="font-size: 12px">《合同条款》</el-link>
                </span>
                <div style="height: 80px"></div>
              </div>
            </template>
          </cardTitleCom>
        </div>

        <cardTitleCom cardTitle="支付方式" v-show="step === 3" v-if="orderDetail">
          <template slot="cardContent">
            <div class="pay-code">
              <div style="line-height: 30px; padding: 15px 0; font-size: 16px">
                应付金额：
                <span style="font-size: 30px; color: #ff5b28">{{ orderDetail.orderMoney }}元</span>
              </div>
              <img :src="QRImgUrl" class="QRImgUrl" style="width: 180px; height: 180px" />
              <div style="
                  color: #666666;
                  font-size: 14px;
                  padding: 15px 0;
                  display: flex;
                  algin-item: center;
                  justify-content: center;
                ">
                <el-image :src="require('@/assets/images/weixinpay.png')" class="icon" />
                <div>微信支付</div>
                <el-image :src="require('@/assets/images/zhifubaopay.png')" class="icon" />
                <div>支付宝支付</div>
                <el-image :src="require('@/assets/images/yunshanpay.png')" class="icon" />
                <div>云闪付</div>
              </div>
              <div style="color: #666666; font-size: 14px; padding: 15px 0">
                温馨提示：产品一经激活启用，非产品原因不允许退货退款
              </div>
            </div>
          </template>
        </cardTitleCom>

        <cardTitleCom cardTitle="支付方式" v-if="step === 4">
          <div class="pay-code" slot="cardContent">
            <i class="el-icon-circle-check" style="color: #28ac3f; font-size: 48px; margin: 15px"></i>
            支付成功
            <div>
              <el-link type="primary" @click="toSP()">进入商户</el-link>
            </div>
          </div>
        </cardTitleCom>
      </div>
    </div>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import myForm from "@/components/my-form";
import { getOrderStateRenew, orderPay } from "@/api/creation/index.js";
import {
  getTenantProductToRenew,
  renewProductByUser,
} from "@/api/tenant/base/info";
import { fcount } from "@/utils";
export default {
  name: "buyStep",
  components: { swiper, swiperSlide, cardTitleCom, myForm },
  props: ["inProductId"],
  data() {
    return {
      active: 0, //
      dateActive: 0, // 时长
      showForm: false,
      QRImgUrl: "",
      checked: false,
      loading: true,
      step: 1,
      payImg: "",
      productFormOption: [],
      productForm: {},
      dictRes: [],
      productData: [],
      timeing: 0, // 监控之间
      timer: null, // 支付状态计时器
      yearsData: [],
      createdData: null,
      timeA: null, // 定时器 - 定时跳转
      type: undefined, // 1: 续费  2：升级
      tenantId: undefined, // 操作的商户ID
      product: [],
      shopsTableSelect: [], // 选择续费的门店
      usersTableSelect: [], // 选择续费的用户
      possTableSelect: [], // 选择续费的站点
      topBtnBoxWidth: undefined,
    };
  },
  computed: {
    topBoxHeight() {
      this.$nextTick(() => {
        this.topBtnBoxWidth =
          document.getElementById("buyStepComBox").clientWidth;
      });
      return this.topBtnBoxWidth;
    },
    // 计算用户订详情
    orderDetail: function () {
      const oneKey = "duration";
      const twoKey = "userNumber";
      const threeKey = "shopNumber";
      const possKey = "possNumber";
      const oneData = this.yearsData;
      const val = this.productForm;
      // console.log('计算价格 0 === ', oneData, val)

      const {
        label,
        productPrice,
        productId: pdID,
        payModelId: pmID,
        initializeUsers,
        initializeShops,
        initializePoss,
        saasProductUsersPrices,
        saasProductShopsPrices,
        saasProductPossPrices,
        initializeShopsPrice,
        initializeUsersPrice,
        initializePossPrice,
        isManageUsers,
        isManageShops,
        isManagePoss,
      } = oneData?.find((x) => x.value === val[oneKey]) || {};
      this.productForm.money = "0" + "元";
      if (!this.productForm[oneKey] || !pdID) {
        return null;
      }

      let money = 0; // 订单价格
      let oldMoney = 0; // 订单原价 前端用
      let unit = ""; // 单位 前端用
      let payModelId = null; // 包年付费方式ID
      let productId = null; // 购买产品ID
      let shopsPriceId = null; // 门店数价格ID
      let usersPriceId = null; // 用户数价格ID
      let possPriceId = null; // 站点数价格ID

      let usersOldMoney = 0; // 用户原价  前端用
      let usersMoney = 0; // 用户支付价格  前端用
      let showUsersPrice = 0; // 用户展示的单价

      let shopsOldMoney = 0; // 门店原价  前端用
      let shopsMoney = 0; // 门店支付价格  前端用
      let showShopPrice = 0; // 门店展示的单价

      let possOldMoney = 0; // 站点原价  前端用
      let possMoney = 0; // 站点支付价格  前端用
      let showPossPrice = 0; // 站点展示的单价
      // 购买时常价格

      if (label && pdID) {
        unit = label;
        productId = pdID;
        payModelId = pmID;
      } else {
        return null;
      }

      // console.log('计算价格usersTableSelect  = ', this.usersTableSelect.length)

      // 购买人数价格
      if (saasProductUsersPrices?.[0]?.usersPrice && isManageUsers) {
        let twoData = saasProductUsersPrices?.sort?.(
          (a, b) => b.users - a.users
        );
        const {
          usersPrice = 0,
          usersOldPrice = 0,
          usersPriceId: upId,
        } = twoData?.find((x) => val[twoKey] >= x.users) || {};

        /*
         * this.productForm[twoKey] 当前选中的用户数
         * usersOldPrice   用户单价
         * */
        usersOldMoney = isManageUsers
          ? fcount([usersOldPrice, this.productForm[twoKey]], "*")
          : 0; // 用户原价  前端用
        usersMoney = isManageUsers
          ? fcount([usersPrice, this.productForm[twoKey]], "*")
          : 0; // 用户支付价格  前端用
        showUsersPrice = isManageUsers ? usersPrice : 0; // 用来展示的用户单价
        //用户价格
        money = usersMoney;
        //原价用户价格
        oldMoney = usersOldMoney;
        usersPriceId = upId;
      }

      // 购买门店价格
      if (saasProductShopsPrices?.[0]?.shopsPrice && isManageShops) {
        let threeData = saasProductShopsPrices?.sort?.(
          (a, b) => b.shops - a.shops
        );
        const {
          shopsPrice = 0,
          shopsOldPrice = 0,
          shopsPriceId: spId,
        } = threeData?.find((x) => val[threeKey] >= x.shops) || {};
        /*
         * this.productForm[twoKey] 当前选中的用户数
         * usersOldPrice   用户单价
         * */
        shopsOldMoney = isManageShops
          ? fcount([shopsOldPrice, this.productForm[threeKey]], "*")
          : 0; // 用户原价  前端用

        shopsMoney = isManageShops
          ? fcount([shopsPrice, this.productForm[threeKey]], "*")
          : 0; // 用户支付价格  前端用
        showShopPrice = isManageShops ? shopsPrice : 0; // 用于显示提示

        money = money + shopsMoney;
        oldMoney = oldMoney + shopsOldMoney;
        shopsPriceId = spId;
      }

      // 购买站点价格
      if (saasProductPossPrices?.[0]?.possPrice && isManagePoss) {
        let threeData = saasProductPossPrices?.sort?.(
          (a, b) => b.poss - a.poss
        );
        const {
          possPrice = 0,
          possOldPrice = 0,
          possPriceId: ppId,
        } = threeData?.find((x) => val[possKey] >= x.poss) || {};
        /*
         * this.productForm[twoKey] 当前选中的用户数
         * usersOldPrice   用户单价
         * */
        possOldMoney = isManagePoss
          ? fcount([possOldPrice, this.productForm[possKey]], "*")
          : 0; // 用户原价  前端用

        possMoney = isManagePoss
          ? fcount([possPrice, this.productForm[possKey]], "*")
          : 0; // 用户支付价格  前端用
        showPossPrice = isManagePoss ? possPrice : 0; // 用于显示提示

        money = money + possMoney;
        oldMoney = oldMoney + possOldMoney;
        possPriceId = ppId;
      }
      this.productForm.money = money + "元";

      const obj = {
        ...val,
        orderMoney: money,
        oldOrderMoney: oldMoney,
        unit,
        payModelId,
        productId,
        shopsPriceId,
        usersPriceId,
        possPriceId,
        shopsQty: val[threeKey],
        usersQty: val[twoKey],
        possQty: val[possKey],
        usersOldMoney,
        usersMoney,
        shopsOldMoney,
        shopsMoney,
        possOldMoney,
        possMoney,
        initializeUsers,
        initializeShops,
        initializePoss,
        showUsersPrice,
        showShopPrice,
        showPossPrice,
        linkManTel: String(val.linkManTel),
      };

      this.QRImgUrl = "";

      return obj;
    },
  },
  created() {
    this.tenantId = this.$route.query.tenantId;
  },
  async mounted() {
    try {
      const { data } = await this.getDicts("product_years_package_mode");
      this.dictRes = data;
      // const res = await Promise.all(
      //     this.product.saasProductResp.map(async x => {
      //         const { data } = await getOnlineProductDetail(x.productId)
      //         return data
      //     })
      // )
      let res = await getTenantProductToRenew({
        tenantId: this.tenantId,
        productBookDetailIds: this.$route.query.ids.split(","),
      });
      this.product = res.data;
      this.productData = res.data;
      this.updateForm();
      this.$nextTick(() => {
        this.topBtnBoxWidth =
          document.getElementById("buyStepComBox").clientWidth;
        window.addEventListener("resize", this.handleResize);
        this.$refs.myForm?.$refs.shopsTableRef?.[0].toggleAllSelection();
        this.$refs.myForm?.$refs.usersTableRef?.[0].toggleAllSelection();
        this.$refs.myForm?.$refs.possTableRef?.[0].toggleAllSelection();
      });
      this.loading = false;
    } catch (error) { }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    "productForm.duration": {
      handler(newDuration) {
        if (this.showForm && newDuration) {
          this.updateForm(
            "duration",
            this.productData[this.active].payModelItems.findIndex(
              (x) => x.payModelType === newDuration
            )
          );
        }
      },
    },
    usersTableSelect(nVal) {
      // console.log('-----12--------', nVal, nVal.length)
      if (nVal.length >= 1) {
        this.productForm.userNumber = nVal.length;
        // this.$forceUpdate()
      }
    },
    shopsTableSelect(nVal) {
      if (nVal.length >= 1) {
        this.productForm.shopNumber = nVal.length;
      }
    },
    possTableSelect(nVal) {
      if (nVal.length >= 1) {
        this.productForm.possNumber = nVal.length;
      }
    },
    step(nVal) {
      if (nVal === 4) {
        let that = this;
        // console.log('=自动 商户信息 == ', that.createdData.tenantId)
        // this.timeA = setTimeout(() => {
        //     that.$tab.closeAllPage()
        //     switchTenant({ tenantId: that.createdData.tenantId })
        //     this.$store.commit('SET_PRODUCTID', that.createdData.productId)
        //     this.$store.commit('SET_TENANTID', that.createdData.tenantId)
        //     this.$store.commit('RESET_ROUTES')
        //     this.$router.push('/index')
        // }, 10000)
      }
    },
  },
  methods: {
    handleResize() {
      this.topBtnBoxWidth =
        document.getElementById("buyStepComBox").clientWidth;
    },
    async updateForm(key, value) {
      if (key) {
        this[key] = value;
      }
      const isDuration = key === "duration";
      // console.log('选择用户列表   = ', this.productData[this.active])
      try {
        this.showForm = false;
        const {
          payModelItems,
          maxUsers,
          maxShops,
          maxPoss,
          userProductItem,
          shopProductItem,
          possProductItem,
        } = this.productData[this.active];
        // console.log('=-=-=-=111111== == ', this.productData)
        if (!isDuration) {
          this.yearsData = payModelItems?.map((x) => {
            const item = this.dictRes.find(
              (y) => y.dictValue === x.payModelType
            );
            return {
              ...x,
              // initializeShops,
              // initializeUsers,
              // initializeShopsPrice,
              // initializeUsersPrice,
              value: x.payModelType,
              label: item.dictLabel,
            };
          });
        }
        // console.log('-=-=-2222-=-=-= ', this.yearsData)
        const {
          isManageUsers,
          isManageShops,
          isManagePoss,
          initializeShops,
          initializeUsers,
          initializePoss,
          initializeShopsPrice,
          initializeUsersPrice,
          initializePossPrice,
          saasProductUsersPrices,
          saasProductShopsPrices,
          saasProductPossPrices,
          value: durationValue,
        } = this.yearsData[isDuration ? value : 0];
        // console.log('用户数金额   === ', this.orderDetail)
        const userShops = [
          userProductItem.length > 0 && isManageUsers
            ? {
              model: "userNumber",
              type: "input-number",
              label: "在线用户数",
              append: {
                type: "xyt-users",
                getPrice: () => ({
                  unit: this.orderDetail.unit,
                  showUsersPrice: this.orderDetail.showUsersPrice,
                  orderMoney: this.orderDetail.usersMoney,
                  oldOrderMoney:
                    this.orderDetail.usersOldMoney >
                      this.orderDetail.usersMoney
                      ? this.orderDetail.usersOldMoney
                      : "",
                }),
              },
              data: userProductItem,
              min: userProductItem.length,
              max: maxUsers,
              initializeUsers: userProductItem.length,
              initializeUsersPrice: initializeUsersPrice,
              precision: 0,
              disabled: true,
              rules: [
                {
                  required: true,
                  message: "请输入在线用户数",
                  trigger: ["blur", "change"],
                },
              ],
            }
            : null,
          userProductItem.length > 0 && isManageUsers
            ? {
              model: "ProductUsersTable",
              type: "table",
              ref: "usersTableRef",
              eltableCheck: true,
              option: {
                tableData: userProductItem,
                tableHand: [
                  { prop: "tenantNo", label: "商户号" },
                  { prop: "lineSn", label: "用户系列号" },
                  { prop: "validBegTime", label: "有效期开始", width: 160 },
                  { prop: "validEndTime", label: "有效期结束", width: 160 },
                ],
                handleSelectionChange: (val) => {
                  if (val.length > 0) {
                    this.usersTableSelect = val;
                  } else if (val.length < 1) {
                    this.$refs.myForm.$refs.usersTableRef?.[0].toggleRowSelection(
                      this.usersTableSelect[0],
                      true
                    );
                  }
                },
              },
            }
            : null,
          shopProductItem.length > 0 && isManageShops
            ? {
              model: "shopNumber",
              type: "input-number",
              label: "门店个数",
              data: shopProductItem,
              min: shopProductItem.length,
              max: maxShops,
              initializeShops: shopProductItem.length,
              initializeShopsPrice: initializeShopsPrice,
              precision: 0,
              disabled: true,
              append: {
                type: "xyt-users",
                getPrice: () => ({
                  showShopPrice: this.orderDetail.showShopPrice,
                  unit: this.orderDetail.unit,
                  orderMoney: this.orderDetail.shopsMoney,
                  oldOrderMoney:
                    this.orderDetail.shopsOldMoney >
                      this.orderDetail.shopsMoney
                      ? this.orderDetail.shopsOldMoney
                      : "",
                }),
              },
              rules: [
                {
                  required: true,
                  message: "请输入门店个数",
                  trigger: ["blur", "change"],
                },
              ],
            }
            : null,
          shopProductItem.length > 0 && isManageShops
            ? {
              model: "ProductShopsTable",
              type: "table",
              ref: "shopsTableRef",
              eltableCheck: true,
              option: {
                tableData: shopProductItem,
                tableHand: [
                  { prop: "tenantNo", label: "商户号" },
                  { prop: "lineSn", label: "门店系列号" },
                  { prop: "validBegTime", label: "有效期开始", width: 160 },
                  { prop: "validEndTime", label: "有效期结束", width: 160 },
                ],
                handleSelectionChange: (val) => {
                  if (val.length > 0) {
                    this.shopsTableSelect = val;
                  } else if (val.length < 1) {
                    this.$refs.myForm.$refs.shopsTableRef?.[0].toggleRowSelection(
                      this.shopsTableSelect[0],
                      true
                    );
                  }
                },
              },
            }
            : null,
          possProductItem.length > 0 && isManagePoss
            ? {
              model: "possNumber",
              type: "input-number",
              label: "站点个数",
              data: possProductItem,
              min: possProductItem.length,
              max: maxPoss,
              initializePoss: possProductItem.length,
              initializePossPrice: initializeShopsPrice,
              precision: 0,
              disabled: true,
              append: {
                type: "xyt-users",
                getPrice: () => ({
                  showPossPrice: this.orderDetail.showPossPrice,
                  unit: this.orderDetail.unit,
                  orderMoney: this.orderDetail.possMoney,
                  oldOrderMoney:
                    this.orderDetail.possOldMoney > this.orderDetail.possMoney
                      ? this.orderDetail.possOldMoney
                      : "",
                }),
              },
              rules: [
                {
                  required: true,
                  message: "请输入站点个数",
                  trigger: ["blur", "change"],
                },
              ],
            }
            : null,
          possProductItem.length > 0 && isManagePoss
            ? {
              model: "ProductPossTable",
              type: "table",
              ref: "possTableRef",
              eltableCheck: true,
              option: {
                tableData: possProductItem,
                tableHand: [
                  { prop: "tenantNo", label: "商户号" },
                  { prop: "lineSn", label: "站点系列号" },
                  { prop: "validBegTime", label: "有效期开始", width: 160 },
                  { prop: "validEndTime", label: "有效期结束", width: 160 },
                ],
                handleSelectionChange: (val) => {
                  if (val.length > 0) {
                    this.possTableSelect = val;
                  } else if (val.length < 1) {
                    this.$refs.myForm.$refs.possTableRef?.[0].toggleRowSelection(
                      this.possTableSelect[0],
                      true
                    );
                  }
                },
              },
            }
            : null,
        ];

        this.productFormOption = [
          {
            model: "duration",
            type: "radio-button",
            label: "购买时长",
            rules: [
              {
                required: true,
                message: "请选择购买时长",
                trigger: ["blur", "change"],
              },
            ],
            optionData: this.yearsData,
          },
          ...userShops.filter((x) => x),
          {
            model: "money",
            type: "text-append",
            label: "合计",
            readonly: true,
          },
        ];

        this.productForm = {
          ...this.productForm,
          isManageUsers,
          isManageShops,
          isManagePoss,
          duration: durationValue, // 购买时长
          userNumber: this.usersTableSelect.length,
          shopNumber: this.shopsTableSelect.length,
          possNumber: this.possTableSelect.length,
        };

        try {
          this.$refs.myForm.$refs.value.clearValidate();
        } catch (error) { }
        this.showForm = true;
      } catch (error) {
        console.log("Errrrrr", error);
      }
    },
    async updateStep(next = false) {
      const clearTimer = () => clearInterval(this.timer);
      if (this.step === 1 && !next) {
        return this.$router.back();
      }
      if (this.step === 4 && next) {
        return this.$emit("update:active", 1);
        // payModelType
      }
      // console.log(this.step)
      if (this.step === 1) {
        try {
          await this.$refs.myForm.$refs.value.validate();
        } catch (error) {
          return this.$message.error(Object.values(error)[0][0].message);
        }
      } else if (this.step === 2 && !this.checked && next) {
        return this.$message.warning("请阅读并同意合同条款");
      } else if (this.step === 2 && next) {
        const loading = this.$loading({
          lock: true,
          text: "订单提交中...",
          target: document.querySelector(".app-main"),
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.0)",
        });
        // 2 创建订单
        const {
          oldOrderMoney = 0,
          credentialUrl = "",
          orderMoney = "",
          payModelId = "",
          productId = "",
          payModel = "",
          shopsPriceId = "",
          usersPriceId = "",
          possPriceId = "",
        } = this.orderDetail;
        // console.log('======+++++++++++=== ', this.orderDetail)

        // 加上时间戳 防止重复
        // orderNo = orderNo + Math.round(new Date())

        let userProductItem = this.usersTableSelect.map(
          (x) => x.productBookDetailId
        );
        let shopProductItem = this.shopsTableSelect.map(
          (x) => x.productBookDetailId
        );
        let possProductItem = this.possTableSelect.map(
          (x) => x.productBookDetailId
        );

        try {
          // 提交续费信息
          const res = await renewProductByUser({
            oldOrderMoney,
            shopsPriceId,
            usersPriceId,
            possPriceId,
            credentialUrl,
            orderMoney,
            payModelId,
            payChannelId: payModel,
            productId,
            userProductItem,
            shopProductItem,
            possProductItem,
            tenantId: this.tenantId,
          });
          let orderNo = res.data;
          this.createdData = {};
          // this.$message.success('续费成功')
          // 3 获取支付码
          const imgRes = await orderPay({
            ordNo: orderNo,
            amt: orderMoney,
            subject: "产品续费",
          });
          this.QRImgUrl = imgRes.data;
          // 每秒检测一次
          this.timer = setInterval(async () => {
            // 一分钟计时
            if (this.timeing <= 60) {
              const res = await getOrderStateRenew({ ordNo: orderNo });
              if (res?.data) {
                // 支付成功
                clearTimer();
                this.createdData = {
                  productId: res.data.productId,
                  tenantId: res.data.tenantId,
                };
                this.step = 4;
              }
            } else {
              clearTimer();
              this.$message.warning("支付超时！");
              this.$emit("update:active", 1);
            }
            this.timeing = this.timeing + 1;
          }, 1000);
          loading.close();
        } catch (error) {
          return loading.close();
        }
      }
      this.step = this.step + (next ? 1 : -1);
      if (this.step !== 3) {
        clearTimer();
      }
    },
    toSP() {
      console.log("购买成功 = ", this.createdData);
      if (this.createdData?.tenantId && this.createdData?.productId) {
        switchTenant({ tenantId: this.createdData.tenantId }).then((res) => {
          this.$store.commit("SET_PRODUCTID", this.createdData.productId);
          this.$store.commit("SET_TENANTID", this.createdData.tenantId);
          this.$store.commit("RESET_ROUTES");
          this.$tab.closeAllPage();
          this.$router.push("/index");
        });
      } else {
        this.$router.push("/creation/console");
      }
    },
  },
  destroyed() {
    clearTimeout(this.timeA);
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  background-color: #eaeaea;
  height: 100vh;
}

.buy-step-com {
  width: 1218px;
  height: 100vh;
  margin: 0 auto;
  //padding: 0 10px;
  overflow-y: scroll;
  /* 隐藏滚动条 */
  ::v-deep -webkit-scrollbar {
    width: 0;
  }

  ::v-deep .my-descriptions-label {
    width: 100px;
    text-align: right;
    display: block;
  }

  ::v-deep .is-process {
    color: #c0c4c0 !important;

    .el-step__icon {
      border: 2px solid #c0c4c0 !important;
    }
  }

  .topBox {
    width: 100%;
    position: fixed;
    z-index: 666;
    background-color: #eaeaea;
  }

  .bottomBox {
    width: 100%;
    padding-top: 173px;
  }

  .top-btns {
    width: 100%;
    //justify-content: flex-end;
    margin: 10px 0;
  }

  .pay-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;

    .icon {
      width: 20px;
      height: 20px;
      margin-left: 15px;
      margin-right: 3px;
    }

    .QRImgUrl {
      padding: 20px;
      border-radius: 30px;
      overflow: hidden;
      border: 2px solid #b3d9e9;
    }
  }

  .productCardBox {
    border: 1px solid #dedede;
    width: 180px;
    height: 180px;
    border-radius: 10px;
    margin-right: 10px;

    cursor: pointer;

    &:hover {
      border: 1px solid #3490ff;
      box-shadow: 0px 3px 20px 1px rgba(51, 142, 255, 0.15);
    }

    .productCard {
      width: 100%;
      height: 140px;
      text-align: center;

      .priceL {
        font-size: 18px;
      }

      .price {
        font-size: 36px;
        font-weight: bold;
      }

      .priceR {
        font-size: 14px;
        color: #666666;
      }
    }

    .explain {
      font-size: 12px;
      width: 100%;
      height: 40px;
      border-top: 1px solid #dedede;
      bottom: 0;
      padding: 0 20px;
    }
  }

  .productCardBox-on {
    background-color: #edf6ff;
    border: 1px solid #3490ff;
    box-shadow: 0px 3px 20px 1px rgba(51, 142, 255, 0.15);
  }
}

.setMealBox {
  width: 80%;
  padding: 10px 0;
  //border: 1px solid #dedede;
  border-radius: 5px;
}

.setMeal {
  min-width: 240px;
  padding: 8px 10px;
  //border-right: 1px solid #dedede;
  border: 1px solid #dedede;

  &:hover {
    border: 1px solid #3490ff;
    box-shadow: 0px 3px 20px 1px rgba(51, 142, 255, 0.15);
  }
}

.setMealBox-on {
  background-color: #edf6ff;
  border: 1px solid #3490ff;
  box-shadow: 0px 3px 20px 1px rgba(51, 142, 255, 0.15);
}

::v-deep .swiper-slide {
  //width: 240px;
}
</style>
